import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit";
import { IncidentInputGroup } from "../../components/formComponents/incidentInputGroup";
import { useMemo, useRef, useState } from "react";
import { IncidentSelect } from "../../components/formComponents/incidentSelect";
import { present } from "async-toolbox";
import { addIdToPatient, PatientSelect } from "../../components/formComponents/patientSelect";
import { selectPatients } from "../../reduxToolkit/selectors/dependents";
import { uniq } from "lodash";
import { IncidentInsert } from "../../../types/supabase";
import { addIncident, IncidentModel } from "../../reduxToolkit/incidentsSlice";


import './incidentCreate.scss'
import { addWeeks } from "date-fns";
import { formatDateInTimeZone } from "../../../lib/formatDateInTimeZone";

export function IncidentCreate() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  
  
  const {membershipId, userId} = useAppSelector((s) => s.membership)
  
  const patients = useAppSelector(selectPatients).map(addIdToPatient)
  
  const allProviders = useAppSelector((s) => uniq(s.expenses.expenses.map((e) => e.provider).filter(present)))
  
  const defaultData = useMemo<Partial<IncidentInsert>>(() => {
    return {
      patient_name: patients[0]?.full_name,
      patient_dob: patients[0]?.date_of_birth,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const [row, setRow] = useState<Partial<IncidentInsert>>({
    ...defaultData,
  })

  const [isAddOn, setIsAddOn] = useState(false)
  const [addOnIncident, setAddOnIncident] = useState<IncidentModel | null>(null)
  const [isUntrackedAddOn, setIsUntrackedAddOn] = useState(false)
  
  
  const formRef = useRef<HTMLFormElement>(null)
  const [wasValidated, setWasValidated] = useState(false)
  
  const doSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!formRef?.current?.reportValidity()) {
      setWasValidated(true)
      return
    }
    
    if (isAddOn && !isUntrackedAddOn) {
      if (!addOnIncident) {
        throw new Error('No incident selected')
      } else {
        navigate(`/incidents/${addOnIncident.id}`)
        return
      }
    }
    
    if (!row.patient_dob || !row.patient_name || !row.description) { throw new Error('Form not filled') }
    
    const now = new Date().toISOString()
    const id = uuidv4()
    dispatch(addIncident({
      id,
      membership_id: membershipId,
      created_at: now,
      updated_at: now,
      created_by_user_id: userId,
      start_date: now,
      description: row.description,
      patient_name: row.patient_name,
      patient_dob: row.patient_dob,
      is_addon_incident: isAddOn,
      is_maternity: row.is_maternity,
      maternity_due_date: row.maternity_due_date,
    }))
    setTimeout(() => {
      navigate(`/incidents/${id}`)
    }, 0);
  }
  
  const selectedPatient = present(row.patient_name) && present(row.patient_dob) &&
      patients.find((p) => p.full_name === row.patient_name && p.date_of_birth === row.patient_dob)
      
  
  const maternity16WeekDate = row.is_maternity && row.maternity_due_date && addWeeks(new Date(row.maternity_due_date), -40 + 16)
  const isWithinFirst16Weeks = maternity16WeekDate && maternity16WeekDate > new Date()
  
  return <div className="incident-create">
    <div className="row incident-create__title">
      <div className="col">
        <h1>Create New Incident</h1>
        
        <p>
          Create a new incident to house your expenses.
        </p>
      </div>
    </div>
    
    <div className="row incident-create__form">
      <div className="col">
        <form ref={formRef} onSubmit={doSubmit} className={`${wasValidated && 'was-validated'}`}>
          <div className={`input-group has-validation`}>
            <label className='input-group-text'>
              <span className="d-none d-md-flex">New Incident Name</span>
              <span className="d-flex d-md-none">Name</span>
            </label>
            <input type="text" className='form-control incident-input-group__description'
              required={true}
              disabled={isAddOn && !isUntrackedAddOn}

              placeholder={isAddOn ? "" : 'yearly checkup / er visit / etc'}
              value={isAddOn && !isUntrackedAddOn ? '' : row?.description || ''}
              onChange={(e) =>  setRow({...row, description: e.target.value})} />
          </div>
          
          <div className='input-group has-validation'>
            <label className='input-group-text'>Patient</label>
            {patients && <PatientSelect className="form-select"
              required
              options={patients}
              value={selectedPatient}
              onChange={({value}) => {
                if (!value) {
                  setRow({
                    ...row,
                    patient_name: undefined,
                    patient_dob: undefined,
                  })
                } else if ('id' in value) {
                  setRow({
                    ...row,
                    patient_name: value.full_name,
                    patient_dob: value.date_of_birth,
                  })
                }
              }} />}


            <div className="invalid-feedback">
              Please choose which of your dependents was the patient.
            </div>
          </div>
          
          <div className={`input-group has-validation mt-2`}>
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                checked={isAddOn}
                onChange={() => {
                  if (isAddOn) {
                    setIsAddOn(false)
                    setAddOnIncident(null)
                    setIsUntrackedAddOn(false)
                  } else {
                    setIsAddOn(true)
                  }
                }}/>
              <label className="form-check-label">
                This is an add-on to an existing incident
              </label>
            </div>
          </div>

          {isAddOn && <div className={`input-group has-validation mt-2 ${!isAddOn && 'd-none'}`}>
            <label className='input-group-text'>
              <span className=" d-none d-md-flex">Add on to which incident?</span>
              <span className=" d-flex d-md-none">Which Incident?</span>
            </label>
            <IncidentSelect
              className='form-select'
              required={isAddOn}

              denyNewIncidents={true}
              denyOldIncidents={true}
              hideUnsubmittedIncidents={true}
              showSubmittedIncidents={true}
              blankOption={false}

              additionalOptions={[
                {
                  key: '!untracked',
                  label: "I didn't track it in this app"
                }
              ]}

              patient={selectedPatient}

              value={isUntrackedAddOn ? '!untracked' : addOnIncident}
              onSelectAdditionalOption={(key) => {
                if (key === '!untracked') {
                  setIsUntrackedAddOn(true)
                }
              }}
              onSelect={(value) => {
                if (present(value)) {
                  setIsAddOn(true)
                  setAddOnIncident(value)
                  setIsUntrackedAddOn(false)
                }
              }} />
          </div>}
          
          <div className={`input-group has-validation mt-2`}>
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                checked={!!row.is_maternity}
                onChange={() => {
                  setRow({
                    ...row,
                    is_maternity: !row.is_maternity
                  })
                }}/>
              <label className="form-check-label">
                This is a Maternity incident
              </label>
            </div>
          </div>
          
          {row.is_maternity && <div className={`input-group has-validation mt-2`}>
            <label className='input-group-text'>Due Date</label>
            <input type="date" className='form-control'
              required={!!row.is_maternity}
              value={row.maternity_due_date || ''}
              onChange={(e) =>  setRow({...row, maternity_due_date: e.target.value})} />
          </div>}
          
          {row.is_maternity && row.maternity_due_date && <div className={`mt-2`}>
            <p>
              Congratulations on your baby! Let's help you get everything covered.
            </p>
            <p>
              The process for covering maternity can be a bit complex, but here are the basics:
              <ul>
                <li>
                  Call CHM to let them know you're pregnant.
                </li>
                <li>
                  Pick a provider, call them and ask for a pre-payment agreement for maternity care.
                </li>
                <li>
                  Submit your pre-payment agreement to CHM via the app.
                </li>
                <li>
                  CHM will send you a check for the pre-payment agreement amount.
                </li>
                <li>
                  Pay your provider.
                </li>
                <li>
                  Have your baby!
                </li>
                <li>
                  Submit any other maternity-related bills to CHM (this is also ongoing during pregnancy).
                </li>
              </ul>
            </p>
            {isWithinFirst16Weeks ? <p className="text-info">
                Make sure you call CHM before {formatDateInTimeZone(maternity16WeekDate, {format: 'MMM dd'})}!
                If you do, you will receive a <b>$500 discount</b> on your Personal Responsibility for this pregnancy.
              </p> : <p>
                Unfortunately, based on the due date you provided, you're past the 16 week mark.  You can still share all your
                maternity expenses with CHM, but your Personal Responsibility for this pregnancy will be higher.
              </p>}
          </div>}
          
          <div className="col-12 d-flex mt-2">
            <button type='submit' className={`btn btn-primary`}>
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
}
