import { wait } from "async-toolbox";
import { format } from "date-fns";
import { Driver, driver } from "driver.js";
import { useCallback, useEffect, useMemo } from "react";
import { present } from "../../../lib/util/present";
import { isMobile } from "../../../lib/util/isMobile";

import "driver.js/dist/driver.css";

function createDriver() {
  const mobile = isMobile();
  
  const quickAddWrapper = mobile ? '.quick-add__mobile' : '.quick-add__desktop';
  const navWrapper = mobile ? '#offcanvasMobileNav' : '.nav-desktop';
  
  const driverObj = driver({
    showProgress: true,
    steps: [
      {
        element: `${quickAddWrapper} .snap-a-photo`,
        popover: {
          title: 'Snap a Receipt',
          description: 'Use your phone camera or upload an itemized bill to automatically scan an expense.',
          align: 'center',
          side: 'top',
          showButtons: ['next', 'close'],
        }
      },
      {
        element: `${quickAddWrapper} .quick-add__manual button`,
        popover: {
          title: 'Or Enter Manually',
          description: 'Quickly record an expense while you\'re at the doctor.',
          showButtons: ['next', 'close'],
          onNextClick: () => {
            const btn = (document.querySelector(`${quickAddWrapper} .quick-add__manual button`) as HTMLButtonElement);
            btn?.click();
            setTimeout(() => {
              driverObj.moveNext();
            }, 100);
          }
        },
      },
      {
        element: `${quickAddWrapper}`,
        popover: {
          title: 'Fill in the details',
          showButtons: ['next', 'close'],
          onNextClick: () => {
            async function doNext() {
              // Fill in the form values
              const formValues: Record<string, any> = {
                'datepic': format(new Date(), 'yyyy-MM-dd'),
                'provider': 'ER Near Me',
                'listedAmount': '2300',
                'paidAmount': '1100',
              }
              for(const key in formValues) {
                await wait(400);
                const input = document.querySelector(`${quickAddWrapper} [name="${key}"]`) as HTMLInputElement;
                console.log('input', input, key, formValues[key])
                if (input) {
                  changeInputValue(input, formValues[key]);
                }
              };
              // Create a new Incident
              await wait(400);
              (document.querySelector(`${quickAddWrapper} .incident-input-group .dropdown-toggle`) as HTMLButtonElement)?.click();
              await wait(400);
              (document.querySelector(`${quickAddWrapper} .incident-input-group [value="!new"]`) as HTMLButtonElement)?.click();
              await wait(400);
              let input = (document.querySelector(`${quickAddWrapper} .incident-input-group__description`) as HTMLInputElement)
              changeInputValue(input, 'Broken Leg (demo incident)');
              await wait(400);
              (document.querySelector(`${quickAddWrapper} [type="submit"]`) as HTMLButtonElement)?.click();
            }

            doNext()
              .catch(console.error)
              .finally(() => {
                setTimeout(() => {
                  driverObj.moveNext();
                }, 100);
              });
          }
        }
      },
      {
        element: '.incidents-show__header-row',
        popover: {
          title: 'Review your incident',
          description: 'The expenses associated with this incident are listed below. You can add more expenses to this incident.',
          showButtons: ['next', 'close'],
        }
      },
      {
        element: '.incident-submit-button',
        popover: {
          title: "When you're ready, submit to CHM",
          showButtons: ['next', 'close'],
          onNextClick: () => {
            (document.querySelector('.incident-submit-button__toggle') as HTMLButtonElement)?.click();
            setTimeout(() => {
              driverObj.moveNext();
            }, 100);
          }
        }
      },
      {
        element: '.incident-submit-button',
        popover: {
          title: 'Review your checklist',
          description: 'The app reminds you of things you need to do before you submit.',
          showButtons: ['next', 'close'],
          onNextClick: () => {
            // navigate to '/expenses' by clicking the link
            (document.querySelector('a.nav-link[href="/todos"') as HTMLAnchorElement)?.click();
            setTimeout(() => {
              driverObj.moveNext();
            }, 100);
          }
        }
      },
      {
        element: mobile ? '.nav-mobile .nav-item__todo-list' : '.nav-desktop .nav-item__todo-list',
        popover: {
          title: 'Your To-Do List, Organized',
          description: 'Let the app tell you what to do next, grouped into incidents and sorted by due date.',
          showButtons: ['next', 'close'],
          align: 'start',
          side: 'top',
          onNextClick: () => {
            if (mobile) {
              // open the offcanvas menu
              (document.querySelector('a[data-bs-toggle="offcanvas"]') as HTMLAnchorElement)?.click();
            } else {
              // open the nav-desktop__more-button
              (document.querySelector('.nav-desktop .nav-desktop__more-button') as HTMLButtonElement)?.click();
            }
            setTimeout(() => {
              driverObj.moveNext();
            }, 100);
          }
        }
      },
      {
        element: `${navWrapper} a[href="/help"]`,
        popover: {
          title: 'Get your questions answered',
          description: 'We are ready to help you with any questions you have about the app.',
          showButtons: ['next', 'close'],
          onNextClick: () => {
            if (mobile) {
              // close the offcanvas menu
              (document.querySelector('a[data-bs-dismiss="offcanvas"]') as HTMLAnchorElement)?.click();
            }
            setTimeout(() => {
              driverObj.moveNext();
            }, 100);
          }
        }
      }
    ]
  });

  return driverObj;
}

export function useTour() {
  const driver = useMemo(() => createDriver(), []);
  
  useEffect(() => {
    const startTour = localStorage.getItem('startTour')
    if (present(startTour)) {
      localStorage.removeItem('startTour');
      driver.drive();
    }
  }, [driver])
  
  return {
    start: useCallback(() => driver.drive(), [driver])
  }
}

// https://stackoverflow.com/a/46012210
function changeInputValue(inputElement: HTMLInputElement, value: string) {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
   )!.set;
   nativeInputValueSetter!.call(inputElement, value);

   const event = new Event('input', { bubbles: true });
   inputElement.dispatchEvent(event);
}
