/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../hooks/reduxToolkit"
import { ExpenseModel, deleteExpense, isCompleteExceptForIncidentID, isCompleteExpense, isTextractJobFailed, isTextractPending, requiredExpenseFields } from "../reduxToolkit/expensesSlice"
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone"
import { EditExpenseButton } from "./editExpenseButton"
import { EditExpenseOnUpdatedFn } from "./forms/editExpenseRowForm"
import { Attachment } from "./attachment"
import { present } from "../../lib/util/present"
import { Tooltip } from "./tooltip"
import { useCheckDocumentAnalysis } from "../hooks/useCheckDocumentAnalysis"
import { formatCurrency } from "../../lib/formatCurrency"
import { useCustomization } from "../hooks/useCustomizations"

import './expenseTable.scss'

const seconds = 1000

interface ExpenseDisplayOptions {
  hideIncident?: boolean

  highlightExpenseId?: string | null
}

interface ExpenseTableProps {
  expenses: ExpenseModel[]

  displayOptions?: ExpenseDisplayOptions

  onExpenseUpdated?: EditExpenseOnUpdatedFn
}

export function ExpenseTable({
  expenses,
  displayOptions,
  onExpenseUpdated
}: ExpenseTableProps) {
  const highlightedExpense = expenses.find((e) => e.id === displayOptions?.highlightExpenseId)

  return <div className="expense-table">
    {highlightedExpense &&
      <ExpenseRow expense={highlightedExpense}
        displayOptions={displayOptions}
        onUpdated={onExpenseUpdated} />}

    {expenses.slice().sort(byDateNullsFirst).map((expense) => {
      if (displayOptions?.highlightExpenseId === expense.id) {
        return null
      }

      return <ExpenseRow key={expense.id} expense={expense} displayOptions={displayOptions} onUpdated={onExpenseUpdated} />
    })}
  </div>
}

interface ExpenseRowProps {
  expense: ExpenseModel,

  displayOptions?: ExpenseDisplayOptions
  onUpdated?: EditExpenseOnUpdatedFn
}

function ExpenseRow({
  expense,
  displayOptions,
  onUpdated
}: ExpenseRowProps) {
  const {id, date, paidAmount, provider, submitted_at} = expense
  const { highlightExpenseId } = displayOptions || {}
  const {hraCardLabel} = useCustomization('hra') || {}

  const [expanded, setExpanded] = useState(highlightExpenseId === id)

  const isIncomplete = !isCompleteExpense(expense)
  const textractPending = isTextractPending(expense)
  const textractFailed = isTextractJobFailed(expense)

  const classes = [
    `expense-table__row col-12 card`,
    highlightExpenseId === id && 'highlighted',
    submitted_at && 'submitted',
    expanded && 'expanded',
    textractPending && !textractFailed && 'scanning',
    isIncomplete && !textractPending && !textractFailed && 'incomplete'
  ].filter(present)
  
  let alert: JSX.Element | undefined
  if (textractPending && !textractFailed) {
    alert = <Tooltip tooltip="Scanning expense to extract information.  This can sometimes take a few minutes.  You can close this and come back later or click the edit button to input information manually.">
      <i className="material-icons flicker">sensors</i>
    </Tooltip>
  } else if (isIncomplete && Date.parse(expense.created_at) < (Date.now() - 10 * seconds)) {
    alert = <Tooltip tooltip={textractFailed ? "Unable to extract expense information" : "This expense is missing some information"}>
      <i className="material-icons text-danger">warning</i>
    </Tooltip>
  }

  return <div id={`expense-table-row_${expense.id}`} className="row">
    <div className={classes.join(' ')}>
      <div className="card-body">
        <div  className={`expense-table__row-header ${expanded && 'expanded'}`} onClick={!expanded ? ((e) => {e.preventDefault(); e.stopPropagation(); setExpanded(true) }) : undefined}>
          <div className="card-title">
            <h5>
              {date ? formatDateInTimeZone(date, { format: 'MM/dd/yyyy' }) : 'Unknown Date'}
              &nbsp;-&nbsp;
              {paidAmount ? formatCurrency(paidAmount) : 'Unknown Amount'}
            </h5>
            
            <div className="expense-table-row__badges">
              {expense.paid_with_hra &&
                <Tooltip tooltip="This expense was paid for with your HRA card.  It will only be included if this incident gets submitted to CHM.">
                  <span className="badge bg-secondary">{hraCardLabel || 'HRA CARD'}</span>
                </Tooltip>}
              
              {alert}
            </div>
          </div>
          <div className="card-subtitle">
            {provider}
          </div>

          <button className="btn btn-link expense-table__row-collapse-toggle"
              aria-expanded={expanded} aria-controls={`#expense-table-${id}`}
              onClick={(e) => {e.preventDefault(); e.stopPropagation(); setExpanded(!expanded) }}
              >
              <i className={`material-icons`}>{expanded ? 'expand_less' : 'expand_more'}</i>
          </button>
        </div>

        <div className={`expense-table__row-collapse collapse ${expanded && 'show'} card-text`} id={`expense-table-${id}`}
            data-id={id}>
          <ExpenseWidget expense={expense}
            displayOptions={displayOptions}
            expanded={expanded}

            onUpdated={onUpdated} />
        </div>
      </div>
    </div>
  </div>
}

interface ExpenseWidgetProps {
  expense: ExpenseModel

  displayOptions?: ExpenseDisplayOptions
  expanded?: boolean
  onUpdated?: EditExpenseOnUpdatedFn
}

function ExpenseWidget({
  expense,
  displayOptions,
  expanded,
  onUpdated
}: ExpenseWidgetProps) {
  const {id, listedAmount, paidAmount, patient_name} = expense

  const dispatch = useAppDispatch()
  const incident = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === expense.incident_id))
  const attachments = useAppSelector((s) => s.attachments.attachments?.filter((a) => a.record_id === expense.id) || [])

  const [loadAttachments, setLoadAttachments] = useState(expanded)
  useEffect(() => {
    if (expanded) {
      setLoadAttachments(true)
    }
  }, [expanded])

  useCheckDocumentAnalysis(id)

  const deleteExpenseWithConfirm = useCallback((e: React.MouseEvent) => {
    e.preventDefault(); e.stopPropagation();

    if (window.confirm('Are you sure you want to delete this expense?')) {
      const now = new Date().toISOString()
      dispatch(deleteExpense({ id, updated_at: now, deleted_at: now }))
    }
  }, [dispatch, id])

  const isIncomplete = !isCompleteExpense(expense)

  let alerts: JSX.Element[] = []
  if (isIncomplete && !isTextractPending(expense) && Date.parse(expense.created_at) < (Date.now() - 10 * seconds)) {
    alerts.push(
    <EditExpenseButton expense={expense} validateOnMount className="alert alert-warning" onUpdated={onUpdated}>
      {isCompleteExceptForIncidentID(expense) ?
        'This expense is not assigned to an incident.  Click here to fix that.' :
        'Unable to automatically extract all expense information!  Click here to fix that.'}
    </EditExpenseButton>) 
  }
  
  const deductions = listedAmount && paidAmount && Math.round((parseFloat(listedAmount) * 100) - (parseFloat(paidAmount) * 100)) / 100

  return <div className="expense-table__widget">
    {alerts}

    {!displayOptions?.hideIncident && present(expense.incident_id) && <div className="row">
      <div className="col">
        <Link to={`/incidents/${incident?.id}`} className="expense-table__widget-incident">
          <h5 className="overline">Incident</h5>
          <span className="d-flex w-100">
          {incident?.description}
          <i className="material-icons ms-auto expense-table__widget-incident__icon">arrow_right_alt</i>
          </span>
        </Link>
      </div>
    </div>}

    <div className="row">
      <div className="col">
        <h5 className="overline">Listed Amount</h5>
        <span>{listedAmount ? formatCurrency(listedAmount) : 'Unknown'}</span>
      </div>

      <div className="col">
        <h5 className="overline">Paid Amount</h5>
        <span>{paidAmount ? formatCurrency(paidAmount) : 'Unknown'}</span>
      </div>

      <div className="col">
        <h5 className="overline">Deductions</h5>
        <span>{deductions && formatCurrency(deductions)}</span>
      </div>

      <div className="col">
        <h5 className="overline">Patient</h5>
        <span>{patient_name}</span>
      </div>
    </div>
    <div className="row expense-table__widget__attachments">
      {
        loadAttachments && attachments.map((attachment) => {
          return <Attachment key={attachment.id} attachment={attachment} downloadable />
        })
      }
    </div>
    <div className="expense-table__widget__actions">
      <EditExpenseButton expense={expense} className="card-link btn btn-outline-info" onUpdated={onUpdated} />
      &nbsp;
      <button className="btn btn-outline-danger" onClick={deleteExpenseWithConfirm}>Delete</button>
    </div>
  </div>
}

function byDateNullsFirst(a: { date?: string | null }, b: { date?: string | null }) {
  if (!present(a.date)) {
    return -1
  }
  if (!present(b.date)) {
    return 1
  }
  
  return Date.parse(b.date) - Date.parse(a.date)
}


