import { useMemo } from "react";
import type { FormStepProps } from "../submitToChm2024";
import { validateSubmissionData2024CHM } from "../validation";
import { useGenerateDocuments2024 } from "./hooks/useGenerateDocuments2024";
import { Tooltip } from "../../../../../components/tooltip";
import { SubmissionModel } from "../../../../../reduxToolkit/submissionsSlice";
import { useResetSubmission } from "../../../../../hooks/useResetSubmission";
import { useCommitSubmission } from "./hooks/useCommitSubmission2024";

interface SubmissionOverview2024Props extends FormStepProps {
  submission: SubmissionModel
}

export function SubmissionOverview2024({ submission, data, updateData, advance }: SubmissionOverview2024Props) {
  const errors = useMemo(() => {
    return validateSubmissionData2024CHM(data)
  }, [data])
  
  const resetSubmission = useResetSubmission(submission)
  
  const [generatedocumentsState, generateDocuments2024] = useGenerateDocuments2024({ submission, data })
  const [_, commitSubmission] = useCommitSubmission({ data, submission })

  const allErrorsFixed = !Object.values(errors).some((errs) => errs?.length)

  return <div className="submitToChm2024-SubmissionOverview">
    <div className="row">
      {allErrorsFixed ?
        <div className="col">
          <p>
            All required fields have been filled in! You're ready to submit your request to CHM.
          </p>
          <p>
            You can review your submission by clicking on the forms below, in case you need to make any changes.
            When you are ready, please click the "Generate Documents" button to get your submission package.
          </p>
        </div> :
        <div className="col">
            <p>
              We've filled in as much as we can, but there are still a few things you need to do before submitting your incident to CHM.
            </p>
            <p>
              Please click on each of the forms below, and ensure that all required fields are filled in.
              When you have completed a form, you will see a green checkmark.
            </p>
          </div>}
    </div>
    <div className="row">
      <div className="col col-md-10 col-xl-8">
        <div className="submitToChm2024-SubmissionOverview__form-status">
          
          <button className="btn btn-accordion submitToChm2024-SubmissionOverview__form-status-item" type="button"
              onClick={() => advance('sharingRequestForm')}>
            <span className="title">Sharing Request Form</span>
            &nbsp;
            {errors.sharingRequestFormData?.length ?
              <span className="badge bg-danger">{errors.sharingRequestFormData.length}</span> :
              <span className="badge bg-success">
                <i className="material-icons">check</i>
              </span>}
            &nbsp;
            <i className="material-icons">keyboard_arrow_right</i>
          </button>
          
          <button className="btn btn-accordion submitToChm2024-SubmissionOverview__form-status-item" type="button"
              onClick={() => advance('medicalBillWorksheet')}>
            <span className="title">Medical Bill Worksheet</span>
            &nbsp;
            {errors.medicalBillWorksheetData?.length ?
              <span className="badge bg-danger">{errors.medicalBillWorksheetData.length}</span> :
              <span className="badge bg-success">
                <i className="material-icons">check</i>
              </span>}
            &nbsp;
            <i className="material-icons">keyboard_arrow_right</i>
          </button>
          
          <button className="btn btn-accordion submitToChm2024-SubmissionOverview__form-status-item" type="button"
              onClick={() => advance('medicalInformationRelease')}>
            <span className="title">Medical Information Release</span>
            &nbsp;
            {errors.medicalInformationReleaseData?.length ?
              <span className="badge bg-danger">{errors.medicalInformationReleaseData.length}</span> :
              <span className="badge bg-success">
                <i className="material-icons">check</i>
              </span>}
            &nbsp;
            <i className="material-icons">keyboard_arrow_right</i>
          </button>
          
          <button className="btn btn-accordion submitToChm2024-SubmissionOverview__form-status-item" type="button"
              onClick={() => advance('maternityVerificationForm')}>
            <span className="title">Maternity Verification Form</span>
            &nbsp;
            {errors.maternityVerificationFormData?.length ?
              <span className="badge bg-danger">{errors.maternityVerificationFormData.length}</span> :
              <span className="badge bg-success">
                <i className="material-icons">check</i>
              </span>}
            &nbsp;
            <i className="material-icons">keyboard_arrow_right</i>
          </button>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        {allErrorsFixed ?
          <button type="button"
              className={`btn btn-primary submitToChm2024-SubmissionOverview__generate-documents-btn ${generatedocumentsState.loading && 'disabled'}`}
              disabled={generatedocumentsState.loading}
              onClick={() => {
                commitSubmission()
                generateDocuments2024()
              }}>
            Generate Documents
            &nbsp;
            {generatedocumentsState.loading ?
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div> :
              <i className="material-icons">folder_zip</i>}
          </button> :
          <Tooltip tooltip="Please address all issues before generating documents">
            <button type="button"
                className="btn btn-outline-secondary disabled">
              Generate Documents
            </button>
          </Tooltip>}
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-12">
        <button onClick={resetSubmission[1]}
            disabled={resetSubmission[0].loading}
            className={`btn btn-outline-danger ${resetSubmission[0].loading && 'disabled'}`}>
          Start Over
        </button>
      </div>
    </div>
  </div>
}
