import { selectToDos } from "../reduxToolkit/selectors/todos"
import { useAppSelector } from "../hooks/reduxToolkit"
import { useTodoCompletion } from "../hooks/todos/useTodoCompletion"
import { useMemo } from "react"
import { NodeApi, Tree } from 'react-arborist'
import { TodoDependency, VirtualToDo } from "../../lib/todos/types"
import { formatDateInTimeZone, parseDateInTimeZone } from "../../lib/formatDateInTimeZone"
import { differenceInDays, endOfYear, startOfDay } from "date-fns"
import { byDueDateAscendingNullsFirst, byStartDateAscendingNullsFirst } from "../../lib/util/sort"
import { isMobile } from "../../lib/util/isMobile"
import { RootState } from "../reduxToolkit/store"

import './todos.scss'
import { present } from "../../lib/util/present"

const ROW_HEIGHT = 24;

export function Todos() {
  const today = useMemo(() => startOfDay(new Date(Date.now())), [])
  const eoy = useMemo(() => endOfYear(new Date(Date.now())), [])

  const [onClick, __, modal] = useTodoCompletion()
  const {todos, tree: currentTree } = useAppSelector((state) => {
    const todos = selectToDos(state)
    const tree = groupTodosByIncident(todos, state)
    return { todos, tree }
  })

  // Memoize the original TODOs because as we complete them they'll disappear from the todo list.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originalTree = useMemo(() => currentTree, [])
  
  // But keep showing the original TODOs until we leave the page.
  const tree = mergeTrees(originalTree, currentTree)

  // Any current TODOs are by definition incomplete (otherwise the selector would not return them).
  const incompleteTodos = todos
  const incompleteTodoKeys = incompleteTodos.map((t) => t.key)

  return <div className="row todos">
    <div className="col-12">
      <h1 className="todos__header">Checklist</h1>
      
      {todos.length === 0 &&
        <p>
          You've taken of everything! Great job!
        </p>}

      <Tree
        height={1000}
        width={'100%'}
        rowHeight={isMobile() ? 3 * ROW_HEIGHT : 2 * ROW_HEIGHT}
        disableDrag
        disableDrop
        disableEdit
        data={tree}>
        {({node, style, tree, dragHandle}) => {

          let dueDate = node.data.todo.dueDate && parseDateInTimeZone(node.data.todo.dueDate)
          if (!dueDate) {
            // walk the tree to find the parent, does the parent have a due date?
            let parent = node.data.parent
            while (parent && parent.todo && !dueDate) {
              dueDate = parent.todo.dueDate && parseDateInTimeZone(parent.todo.dueDate)
              parent = parent.parent
            }
          }

          const isChild = present(node.data.parent)
          const isPast = dueDate && dueDate < today
          const dueSoon = !dueDate || differenceInDays(dueDate, new Date()) <= 14
          const showYear = dueDate && dueDate > eoy

          const hasChildren = node.children && node.children.length > 0
          const complete = isComplete(node, incompleteTodoKeys)

          return <div ref={dragHandle} style={style}
                className={`todos__list-item ${complete ? 'complete' : 'incomplete'} ${isChild && 'isChild'} ${hasChildren && 'hasChildren'} ${node.isOpen && 'expanded'}`}>
              <span className="todos__list-item__expander" onClick={() => { node.toggle() }}>
                {node.children && node.children.length > 0 &&
                  <i className="material-icons">
                    {node.isOpen ? 'expand_less' : 'expand_more'}
                  </i>}
              </span>

              <div className="todos__list-item__content"
                  onClick={() => {
                    if (node.data.todo.action) {
                      onClick(node.data.todo)
                    } else {
                      node.toggle()
                    }   
                  }}>
                <span className="todos__list-item__text">{node.data.todo.title}</span>

                <div className="todos__list-item__actions">
                  {dueDate && <span className={`todos__list-item__due-date badge ${dueSoon ? 'bg-danger' : 'bg-secondary '}`}>
                    <span className="d-none d-md-inline">due&nbsp;</span>{
                    (isPast) ?
                      'ASAP' :
                      formatDateInTimeZone(dueDate, { format: showYear ? 'MMM dd, yyyy' : 'MMM dd' })
                    }
                  </span>}

                  {node.data.todo.action && <i className="material-icons d-none d-md-inline-block">{
                    complete ? 'check_box' : 'check_box_outline_blank'
                  }</i>}
                </div>
              </div>
            </div>
          }}
      </Tree>

      {modal}
    </div>
  </div>
}

type IncidentDescription = {
  key: string,
  title: string,
  
  start_date: string | null
  
  // these fields never exist but we define them so we don't have to do `if ('dueDate' in todo)`
  dueDate?: undefined,
  action?: undefined,
  dependsOn?: undefined
}

type Data = {
  id: string,
  todo: VirtualToDo | IncidentDescription,
  parent?: Data
  children?: Data[],
  isOpen?: boolean,
}

function groupTodosByIncident(todos: VirtualToDo[], state: RootState): Data[] {
  // Group the todos by incident
  const todosByIncident: Record<string, VirtualToDo[]> = {}
  const orphanTodos: VirtualToDo[] = []
  
  for (const todo of todos) {
    let incidentId: string | null | undefined
    switch(todo.record_type) {
      case 'incident':
        incidentId = todo.record_id
        break
      case 'expense':
        incidentId = state.expenses.expenses.find((e) => e.id === todo.record_id)?.incident_id
        break
      case 'submission':
        incidentId = state.submissions.submissions.find((s) => s.id === todo.record_id)?.incident_id
        break
      case 'advance':
        incidentId = state.advances?.advances?.find((a) => a.id === todo.record_id)?.incident_id
        break
    }
    
    if (incidentId) {
      todosByIncident[incidentId] ||= []
      todosByIncident[incidentId].push(todo)
    } else {
      orphanTodos.push(todo)
    }
  }
  
  // create a root node for each incident sorted by start date
  const rootNodes: Data[] = Object.keys(todosByIncident).map((incidentId) => {
    const incident = state.incidents.incidents.find((i) => i.id === incidentId)
    const desc = incident?.description || 'Unknown Incident'
    return {
      id: incidentId,
      todo: {
        key: incidentId,
        title: [
          incident?.start_date ? formatDateInTimeZone(incident.start_date, { format: 'LLL d' }) : null,
          desc,
          incident?.patient_name ? incident.patient_name.split(' ')[0] : null
        ].filter(present).join(' - '),
        start_date: incident?.start_date || null
      },
      children: createTreeFromTodos(todosByIncident[incidentId])
    }
  }).sort((a, b) => byStartDateAscendingNullsFirst(a.todo, b.todo))
  
  // add orphan todos to the root
  if (orphanTodos.length > 0) {
    const orphanNodes: Data[] = createTreeFromTodos(orphanTodos)
    rootNodes.push(...orphanNodes)
  }

  return rootNodes
}

// Given a list of todos for a single incident, create a single level of tree nodes
// with dependent TODO items grouped together with their parents.
function createTreeFromTodos(todos: VirtualToDo[]): Data[] {
  // first pass - identify all todos that have a parent
  const todosWithParents: string[] = []
  todos.forEach((t) => {
    if (t.dependsOn) {
      todosWithParents.push(...t.dependsOn.map((d) => d.key))
    }
  })

  // now - find the parent nodes sorted by due date
  const parentNodes: Data[] = todos
    .filter((t) => !todosWithParents.includes(t.key))
    .map((parentTodo) => {
      const parentData: Data = {
        id: parentTodo.key,
        todo: parentTodo,
        isOpen: true,
        children: undefined
      }
      
      // TODO: flatten dependency list if we have more than 1 depth of dependency
      const dependentKeys = parentTodo.dependsOn?.map((dep) => dep.key)
      if (dependentKeys) {
        parentData.children = dependentKeys.map((key) => {
          const child = todos.find((t) => t.key === key)
          if (!child) {
            // This todo was already completed, so it's not in the list anymore.
            return null
          }

          const childData: Data = {
            id: key,
            todo: child,
            parent: parentData
          }
          return childData
        }).filter(present)
          .sort((a, b) => byDueDateAscendingNullsFirst(a.todo, b.todo))
      }
      return parentData
    })
    .sort((a, b) => byDueDateAscendingNullsFirst(a.todo, b.todo))

  // Extract the children in front of each parent node
  const finalList: Data[] = []
  for (const parentNode of parentNodes) {
    if (parentNode.children) {
      finalList.push(...parentNode.children)
      parentNode.children = undefined
    }
    finalList.push(parentNode)
  }
  
  return finalList
}

function mergeTrees(originaltree: Data[], currentTree: Data[]): Data[] {
  const newTree: Data[] = originaltree
  
  for (const node of currentTree) {
    const originalNode = originaltree.find((t) => t.id === node.id)
    if (!originalNode) {
      newTree.push(node)
    } else {
      // merge the children
      if (node.children) {
        originalNode.children = mergeTrees(originalNode.children || [], node.children)
      }
    }
  }
  
  return newTree
}

function isComplete(node: NodeApi<Data>, incompleteTodoKeys: string[]): boolean {
  if (node.children && node.children.length > 0) {
    return node.children.every((child) => isComplete(child, incompleteTodoKeys))
  }

  return !incompleteTodoKeys.includes(node.data.todo.key)
}
