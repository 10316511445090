import { NotNull } from "../../../types/util"
import { AdvanceModel, AdvanceRequestModel } from "../advancesSlice"
import { ExpenseModel, hasIncidentId } from "../expensesSlice"
import { IncidentModel } from "../incidentsSlice"
import { RootState } from "../store"
import { SubmissionModel } from "../submissionsSlice"

export type IncidentWithExpensesAndSubmissions = IncidentModel & {
  expenses: ExpenseModel[],
  submissions: SubmissionModel[],
  advances: AdvanceModel[],
  advance_requests: AdvanceRequestModel[]
}

export function selectIncidentsWithExpensesAndSubmissions(state: RootState) {
  const expensesByIncident = groupByIncidentId(state.expenses.expenses.filter(hasIncidentId));
  const submissionsByIncident = groupByIncidentId(state.submissions.submissions);
  const advancesByIncident = groupByIncidentId(state.advances?.advances || []);
  const advanceRequestsByIncident = groupByIncidentId(state.advances?.advanceRequests || []);

  return state.incidents.incidents.map(incident => ({
    ...incident,
    expenses: expensesByIncident[incident.id] || [],
    submissions: submissionsByIncident[incident.id] || [],
    advances: advancesByIncident[incident.id] || [],
    advance_requests: advanceRequestsByIncident[incident.id] || [],
  }));
}

function groupByIncidentId<T extends { incident_id: string }>(items: T[]): Record<string, T[]> {
  return items.reduce((acc, item) => {
    if (!acc[item.incident_id]) {
      acc[item.incident_id] = [];
    }
    acc[item.incident_id].push(item);
    return acc;
  }, {} as Record<string, T[]>);
}

