import { useAppSelector } from "../hooks/reduxToolkit";

/**
 * Checks the given feature flag to see if it is enabled for this user.
 * 
 * Currently feature flags are enabled on a per-organization basis.
 */
export function useFeature(feature: string): boolean {
  return useAppSelector(s => s.membership?.organization?.feature_flags || []).includes(feature)
}
