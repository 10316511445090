import { isInstalledApp } from "./isInstalledApp"

export function downloadFile(
  filename: string,
  data: Blob
) {
  // Inside of PWA, use share dialog instead of opening a new tab that the user can't close
  if (typeof navigator.share === 'function' && isInstalledApp()) {
    const file = new File([data], filename || 'file', { type: data.type })
    navigator.share({ files: [file] })
    return
  }

  const downloadUrl = window.URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = downloadUrl
  tempLink.setAttribute('download', filename)
  tempLink.click()
}
