import { AppSupabaseClient, MembershipRow, MembershipRoleRow } from "../../types/supabase"
import { present } from "../util/present"

export async function updateMemberNumber(
  client: AppSupabaseClient,
  params: {
    membershipId: string,
    memberNumber: string,
  }
): Promise<{ member_number: string }> {
  const { membershipId, memberNumber } = params
  const membershipUpdate = await client.from('memberships')
    .update({
      member_number: memberNumber
    })
    .eq('id', membershipId)
    .select('member_number')
    
  const member_number = membershipUpdate.data![0].member_number
  if (!present(member_number)) {
    throw new Error(`Unable to update member number for membership ${membershipId}`)
  }

  return {
    member_number
  }
}
