import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AdvanceInsert, AdvanceRequestInsert, AdvanceRequestRow, AdvanceRow, NotDeleted } from '../../types/supabase'
import type { DeepPartialNullable, NotNull } from '../../types/util'
import { PURGE } from 'redux-persist';
import { replaceOrInsert } from '../../lib/util/replaceOrInsert'
import { redactObjectExceptKeys } from '../../lib/util/redact'
import { onSyncDownComplete } from './actions/onSyncDownComplete';

export type AdvanceModel = NotNull<NotDeleted<AdvanceInsert>, 'id' | 'created_at' | 'updated_at' | 'membership_id' | 'incident_id' | 'amount' | 'expense_ids'>
export type AdvanceRequestModel = NotNull<NotDeleted<AdvanceRequestInsert>, 'id' | 'created_at' | 'updated_at' | 'created_by_user_id' | 'membership_id' | 'incident_id' | 'amount' | 'expense_ids'>

type AdvanceRequestInsertPayload = AdvanceRequestModel

export type AdvancesSliceState = {
  advances: Array<AdvanceModel>
  advanceRequests: Array<AdvanceRequestModel>

  lastLoadFromServer?: number
}

const initialState: AdvancesSliceState = {
  advances: [],
  advanceRequests: []
}

export const AdvancesSlice = createSlice({
  name: 'Advances',
  initialState,
  reducers: {
    createAdvanceRequest(state, action: PayloadAction<AdvanceRequestInsertPayload>) {
      if (!state.advanceRequests) { state.advanceRequests = [] }
      const i = state.advanceRequests.findIndex((a) => a.id === action.payload.id)
      if (i >= 0) {
        state.advanceRequests[i] = action.payload
      } else {
        state.advanceRequests.push(action.payload)
      }
    },
    deleteAdvanceRequest(state, action: PayloadAction<{ id: string, updated_at: string, deleted_at: string }>) {
      // Remove it out of the AdvanceRequests array
      if (!state.advanceRequests) { state.advanceRequests = [] }
      const i = state.advanceRequests.findIndex((e) => e.id === action.payload.id)
      if (i >= 0) {
        state.advanceRequests.splice(i, 1)
      }
    },
    
    indicateAdvanceRepaid(state, action: PayloadAction<{ id: string, updated_at: string, indicated_repaid_at: string }>) {
      const advance = state.advances?.find((a) => a.id === action.payload.id)
      if (advance) {
        advance.updated_at = action.payload.updated_at
        advance.indicated_repaid_at = action.payload.indicated_repaid_at
      }
    },
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    }).addCase(onSyncDownComplete, (state, action) => {
      state.advances = state.advances || []
      state.advanceRequests = state.advanceRequests || []
      
      for (const advance of action.payload.advances?.updated || []) {
        replaceOrInsert(state.advances, advance)
      }
      for (const deletedAdvance of action.payload.advances?.deleted || []) {
        const i = state.advances.findIndex((t) => t.id === deletedAdvance.id)
        if (i >= 0 && state.advances[i].updated_at <= deletedAdvance.updated_at) {
          state.advances.splice(i, 1)
        }
      }
      
      for (const advanceRequest of action.payload.advance_requests?.updated || []) {
        replaceOrInsert(state.advanceRequests, advanceRequest)
      }
      for (const deletedAdvanceRequest of action.payload.advance_requests?.deleted || []) {
        const i = state.advanceRequests.findIndex((t) => t.id === deletedAdvanceRequest.id)
        if (i >= 0 && state.advanceRequests[i].updated_at <= deletedAdvanceRequest.updated_at) {
          state.advanceRequests.splice(i, 1)
        }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  createAdvanceRequest,
  deleteAdvanceRequest,
  indicateAdvanceRepaid
} = AdvancesSlice.actions

export type AdvancesSliceAction = ReturnType<typeof AdvancesSlice.actions[keyof typeof AdvancesSlice.actions]>

export function isAdvancesSliceAction(action: any): action is AdvancesSliceAction {
  return action.type?.startsWith(AdvancesSlice.name)
}

export default AdvancesSlice.reducer

export type PendingAdvanceRequest = AdvanceRequestModel & { approved_at?: null, rejected_at?: null }
export function isPendingAdvanceRequest(ar: AdvanceRequestModel): ar is PendingAdvanceRequest {
  return !ar.approved_at && !ar.rejected_at
}

export type ApprovedAdvanceRequest = AdvanceRequestModel & { approved_at: string, rejected_at?: null }
export function isApprovedAdvanceRequest(ar: AdvanceRequestModel): ar is ApprovedAdvanceRequest {
  return !!ar.approved_at && !ar.rejected_at
}

export type RejectedAdvanceRequest = AdvanceRequestModel & { approved_at?: null, rejected_at: string }
export function isRejectedAdvanceRequest(ar: AdvanceRequestModel): ar is RejectedAdvanceRequest {
  return !ar.approved_at && !!ar.rejected_at
}

export type OpenAdvance = AdvanceModel & { repaid_at?: null }
export function isOpenAdvance(a: AdvanceModel): a is OpenAdvance {
  return !a.repaid_at
}

export type RepaidAdvance = AdvanceModel & { repaid_at: string }
export function isRepaidAdvance(a: AdvanceModel): a is RepaidAdvance {
  return !!a.repaid_at
}

/*
 * Redact all sensitive information from the AdvanceRequests, so that they can be sent to Analytics tools like
 * Sentry or Amplitude.
 * Due to HIPAA compliance, we cannot send any patient information to these tools.
 * The redaction operates with a whitelist to ensure that we don't accidentally send any sensitive information in the future.
*/

export function redactAdvances(state: AdvancesSliceState): DeepPartialNullable<AdvancesSliceState> {
  return {
    advances: state.advances?.map(redactAdvance),
    advanceRequests: state.advanceRequests?.map(redactAdvanceRequest)
  }
}

export function redactAdvance(Advance: Partial<AdvanceRow>): DeepPartialNullable<AdvanceRow> {
  return {
    ...redactObjectExceptKeys(Advance,
      'id', 'created_at', 'updated_at', 'membership_id', 'incident_id', 'deleted_at', 'indicated_repaid_at', 'repaid_at', 'expense_ids'),
  }
}

export function redactAdvanceRequest(AdvanceRequest: Partial<AdvanceRequestRow>): DeepPartialNullable<AdvanceRequestRow> {
  return {
    ...redactObjectExceptKeys(AdvanceRequest,
      'id', 'created_at', 'updated_at', 'created_by_user_id', 'membership_id', 'deleted_at', 'expense_ids', 'approved_at', 'rejected_at'),
  }
}

export function redactAdvancesSliceAction(action: AdvancesSliceAction) {
  switch(action.type) {
    case createAdvanceRequest.type:
    case deleteAdvanceRequest.type:
      return {
        type: action.type,
        payload: redactAdvanceRequest(action.payload)
      }
      
    case indicateAdvanceRepaid.type:
      return {
        type: action.type,
        payload: redactAdvance(action.payload)
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

