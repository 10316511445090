import { useMemo } from "react";
import max from "lodash/max";
import { useFuseSearch } from "./useFuseSearch";
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone";
import { IncidentModel } from "../reduxToolkit/incidentsSlice";
import { useAppSelector } from "./reduxToolkit";
import { present } from "../../lib/util/present";
import { groupBy } from "lodash";

interface IncidentDocument extends IncidentModel {
  longStartDate?: string | null
  
  providers: string[]
}

const searchKeys: Array<keyof IncidentDocument> = [
  'description',
  'patient_name',
  'start_date',
  'longStartDate',
  'providers'
]

export function useIncidentSearch<T extends IncidentModel>(
  incidents: T[],
) {
  const incidentIDs = incidents.map(i => i.id)
  const expenseProvidersByIncident = useAppSelector((state) => {
    const filteredExpenses = state.expenses.expenses.filter((e) => e.incident_id && incidentIDs.includes(e.incident_id))
    const groupedExpenses = groupBy(filteredExpenses, 'incident_id')
    
    const providersByIncident: Record<string, string[]> = {}
    for(const [incidentId, expenses] of Object.entries(groupedExpenses)) {
      providersByIncident[incidentId] = expenses.map(e => e.provider).filter(present)
    }
    return providersByIncident
  })
  
  const updatedAt = max(incidents?.map((e) => e.updated_at)) || 0

  const docs: Array<IncidentDocument> | undefined = useMemo(() => {
    return incidents?.map((incident) => {
      return {
        ...incident,
        longStartDate: incident.start_date ? formatDateInTimeZone(incident.start_date, { format: 'MMMM dd, yyyy' }) : null,
        providers: expenseProvidersByIncident[incident.id] || []
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAt])

  const [resultDocs, search, state] = useFuseSearch(
      docs || [],
      {
        findAllMatches: true,
        includeScore: true,
        keys: searchKeys,
      },
      [
        // Reindex the fuse search whenever the expenses change (i.e. the latest updated at changes)
        updatedAt,
      ]
    )
  
  const results = resultDocs?.map((doc) => incidents.find((i) => i.id === doc.id)).filter(present)
  return [results, search, state] as const
}
