import { selectMembershipForUser } from "./selectMembershipForUser"
import { selectUsersAndDependentsForMembership } from "./selectUsersAndDependentsForMembership"
import { hasCompletedOnboarding } from "../../app/reduxToolkit/membershipSlice"
import { AppSupabaseClient, DependentRow, MembershipRoleRow, MembershipRow, OrganizationRow, ProfileRow, SettingsRow } from "../../types/supabase"
import { getOrCreateSettingsForUser } from "./getOrCreateSettingsForUser"
import { selectOrganizationForUser } from "./selectOrganizationForUser"

type ReduxStateResult =
  {
    loadedAt: undefined,
    hasCompletedOnboarding: false,
    settings: SettingsRow,
    membership?: undefined
    role?: undefined
    profile?: undefined
    dependents?: undefined
    dependentProfiles?: undefined
    organization?: undefined
  } | {
    loadedAt: number,
    hasCompletedOnboarding: boolean,
    membership: MembershipRow,
    role: MembershipRoleRow,
    profile: ProfileRow,
    settings: SettingsRow,
    dependents: DependentRow[],
    dependentProfiles: ProfileRow[]
    organization: OrganizationRow | null
  }

/**
 * This query is run when the app is loaded, or when it comes back online,
 * to pull the latest state from the server.
 */
export async function loadReduxStateFromServer(
  client: AppSupabaseClient,
  params: {
    userId: string
  }
): Promise<ReduxStateResult> {
  const {userId} = params

  const row = await selectMembershipForUser(client, { userId: userId })

  const settings = await getOrCreateSettingsForUser(client, { userId: userId })

  if (!row) {
    return {
      loadedAt: undefined,
      hasCompletedOnboarding: false,
      settings: settings
    }
  }

  const {role, membership, profile} = row
  const {dependents, profiles} = await selectUsersAndDependentsForMembership(client, { membershipId: membership.id })

  // Here we only care to sync the organization if the user is already in the organization_memberships by ID.
  const organization = await selectOrganizationForUser(
    client,
    {
      email: null,
      id: userId
    }
  )

  return {
    loadedAt: Date.now(),
    hasCompletedOnboarding: hasCompletedOnboarding(profile),
    membership,
    role,
    profile,
    settings,
    dependents,
    dependentProfiles: profiles,
    organization
  }
}

