import { isInstalledApp } from "./isInstalledApp"

export function openFile(
  data: Blob,
  fileName?: string
) {
  // Inside of PWA, use share dialog instead of opening a new tab that the user can't close
  if (typeof navigator.share === 'function' && isInstalledApp()) {
    const file = new File([data], fileName || 'file', { type: data.type })
    navigator.share({ files: [file] })
    return
  }
  
  const downloadUrl = window.URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = downloadUrl
  if (fileName) {
    tempLink.setAttribute('download', fileName)
  }
  tempLink.target = '_blank'

  tempLink.click()
}
