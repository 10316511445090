import { present } from "async-toolbox"
import { cloneDeep } from "lodash"
import { useMemo, useState, useCallback } from "react"
import { SubmissionData2024CHM } from "../../../../../reduxToolkit/submissionsSlice"
import { FormStepProps } from "../submitToChm2024"
import { Tooltip } from "../../../../../components/tooltip"
import { SignatureCanvas } from "../../../../../components/signatureCanvas"
import { FloatingActionButtons } from "./components/floatingActionButtons"
import { ValidationError, validateMaternityVerificationFormData2024 } from "../validation"
import { CheckboxYesNoInputGroup } from "./components/checkboxYesNoInputGroup"
import { FormCheckInlineInputGroup } from "./components/formCheckInlineInputGroup"
import { TextInputGroup } from "./components/textInputGroup"
import { FormSelectInputGroup } from "./components/formSelectInputGroup"


export function MaternityVerificationForm2024({ data: upstreamData, updateData, advance, home }: FormStepProps) {
  const originalData = useMemo(() => {
    return cloneDeep(upstreamData || {})
  }, [])
  const [data, setData] = useState(originalData.maternityVerificationFormData || {})

  const errors = useMemo<ValidationError[]>(() => {
    return validateMaternityVerificationFormData2024(data)
  }, [data])

  const changeData = useCallback(<TKey extends keyof SubmissionData2024CHM['maternityVerificationFormData']>(key: TKey, value: SubmissionData2024CHM['maternityVerificationFormData'][TKey]) => {
    setData((data) => {
      if (data && value === data[key]) { return data }

      const newData = {
        ...data,
        [key]: value
      }
      updateData({
        ...originalData,
        maternityVerificationFormData: newData
      })

      return newData
    })
  }, [updateData, originalData])
  
  return <>
  <FloatingActionButtons home={home} advance={advance} errors={errors} />
  <div className="submitToChm2024-MaternityVerificationForm">
    

    <form onSubmit={((evt) => { evt?.preventDefault(); advance() })}>
      <div className="accordion" id='maternity-verification-form'>
        <CheckboxYesNoInputGroup field={'isMaternityIncident'}
          className="is-maternity-incident"
          label={'Is this a maternity incident?'}
          value={data.isMaternityIncident}
          changeData={changeData}
          validationError={errors.find((e) => e.field === 'isMaternityIncident')} />
          
        <div className={`accordion-item ${!data.isMaternityIncident && 'd-none'}`}>
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#patient-and-illness'
                aria-expanded="true" aria-controls="patient-and-illness">
              1. Patient information
            </button>
          </h2>
          
          <div className="accordion-collapse collapse show" id='patient-and-illness' data-bs-parent='maternity-verification-form'>
            <div className="accordion-body">
              <div className="input-group">
                <label className="input-group-text">Patient Name:</label>
                <input type="text" className="form-control" 
                  value={data.patientName || undefined}
                  onChange={
                    useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                      const value = present(evt.target.value) ? evt.target.value : null
                      changeData('patientName', value)
                    }, [changeData])
                  }/>
              </div>

              <div className="input-group">
                <label className="input-group-text">Member Number:</label>
                <div className={`form-control disabled`}>
                <Tooltip tooltip="Your CHM member number is set in your profile.  If this is wrong please contact us to correct it.">
                  {data.memberNumber}
                </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className={`accordion-item ${!data.isMaternityIncident && 'd-none'}`}>
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#consent-to-release'
                aria-expanded="true" aria-controls="consent-to-release">
              2. Qualifying for sharing
            </button>
          </h2>
          
          <div className="accordion-collapse collapse show" id='consent-to-release' data-bs-parent='maternity-verification-form'>
            <div className="accordion-body">
              <p>
                To qualify for maternity sharing, CHM members must meet the following criteria:
                <ul>
                  <li>The member must be married at the time of conception.</li>
                  <li>The member must have participated at CHM Gold at least 300 days prior to the expected due date.</li>
                </ul>
              </p>
              <p>
                The entire maternity incident is ineligible if the member does not meet the preceding qualifications.
              </p>
              
              <FormCheckInlineInputGroup field={'isIVF'}
                  className="input-group__long-text"
                  label="Was the pregnancy a result of IVF (in vitro fertilization) or embryo implant, transfer, or adoption?"
                  value={data.isIVF}
                  changeData={changeData}
                  validationError={errors.find((e) => e.field === 'isIVF')}
                  >
                {data.isIVF === true && <i>
                  Pregnancies resulting from these treatments/procedures are not eligible (see CHM Guidelines for more information).
                </i>}
              </FormCheckInlineInputGroup>

              <TextInputGroup field={'dueDate'}
                inputType="date"
                className='input-group__long-text'
                label={
                  data.isActualDateOfBirth ?
                    "Baby's DOB" :
                    data.isExpectedDueDate ?
                      "Expected Due Date" :
                      "Baby's DOB (or expected due date)"
                }
                value={data.dueDate}
                changeData={(field, value) => {
                  changeData(field, value)
                  if (present(value)) {
                    // Mark and save whether the date is expected or actual
                    if (Date.parse(value) > Date.now()) {
                      changeData('isExpectedDueDate', true)
                      changeData('isActualDateOfBirth', false)
                    } else {
                      changeData('isExpectedDueDate', false)
                      changeData('isActualDateOfBirth', true)
                    }
                  }
                }}
                validationError={errors.find((e) => e.field === 'dueDate')} />

              <FormCheckInlineInputGroup field={'credits'}
                className="input-group__long-text"
                label="I’d like any available credits applied to my Personal Responsibility"
                value={data.credits}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'credits')}>
              </FormCheckInlineInputGroup>
            </div>
          </div>
        </div>

        <div className={`accordion-item ${!data.isMaternityIncident && 'd-none'}`}>
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
              data-bs-toggle='collapse' data-bs-target='#maternity-information'
              aria-expanded="true" aria-controls="maternity-information">
              3. Maternity information
            </button>
          </h2>

          <div className="accordion-collapse collapse show" id='maternity-information' data-bs-parent='maternity-verification-form'>
            <div className="accordion-body">
              <FormSelectInputGroup field={'deliveryLocation'}
                  label="Delivery location:"
                  value={data.deliveryLocation}
                  changeData={changeData}
                  validationError={errors.find((e) => e.field === 'deliveryLocation')}>
                <option value="">Select one...</option>
                <option value="hospital">Hospital</option>
                <option value="birthing-center">Birthing Center</option>
                <option value="home">Home</option>
              </FormSelectInputGroup>

              <FormSelectInputGroup field={'chosenHospital'}
                  label="Have you chosen a hospital or birthing center yet?"
                  value={typeof data.chosenHospital == 'boolean' ? data.chosenHospital ? 'yes' : 'no' : undefined}
                  className="input-group__long-text"
                  changeData={(_key, value) => {
                    changeData('chosenHospital', value === 'yes' ? true : false);
                  }}
                  validationError={errors.find((e) => e.field === 'chosenHospital')}>
                <option value="">Select one...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </FormSelectInputGroup>
              
              <p></p>
              <span>Do you have primary forms of payment available?</span>

              <FormSelectInputGroup field={'paymentForms'}
                  value={data.paymentForms}
                  className="input-group__long-text"
                  changeData={changeData}
                  validationError={errors.find((e) => e.field === 'paymentForms')}>
                <option value="">No</option>
                <option value="primary-insurance">Primary insurance</option>
                <option value="medicaid">Medicaid</option>
                <option value="financial-assistance">Financial assistance</option>
                <option value="other">Other</option>
              </FormSelectInputGroup>

              <TextInputGroup field={'otherPaymentForms'}
                label="Please specify:"
                value={data.otherPaymentForms || ''}
                changeData={changeData}
                className={data.paymentForms === 'other' ? '' : 'd-none'}
                validationError={errors.find((e) => e.field === 'otherPaymentForms')} />

              <TextInputGroup field={'paymentFormsStartDate'}
                label="Start date:"
                value={data.paymentFormsStartDate || ''}
                changeData={changeData}
                className={present(data.paymentForms) ? '' : 'd-none'}
                validationError={errors.find((e) => e.field === 'paymentFormsStartDate')} />
                
              <TextInputGroup field={'paymentFormsEndDate'}
                label="End date:"
                value={data.paymentFormsEndDate || ''}
                changeData={changeData}
                className={present(data.paymentForms) ? '' : 'd-none'}
                validationError={errors.find((e) => e.field === 'paymentFormsEndDate')} />
            </div>
          </div>
        </div>

        <div className={`accordion-item ${data.isMaternityIncident || 'd-none'}`}>
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
              data-bs-toggle='collapse' data-bs-target='#consent'
              aria-expanded="true" aria-controls="consent">
              4. Consent
            </button>
          </h2>

          <div className="accordion-collapse collapse show" id='consent' data-bs-parent='maternity-verification-form'>
            <div className="accordion-body">
              <p>
                I understand that CHM members participate out of a desire to share one another’s burdens, and it would be an abuse of their trust
                if I use the money I receive for shared medical bills for some purpose other than payment of those medical bills. If I have prepaid
                or made payments, I will consider funds received from CHM as reimbursement. I understand that failure to provide accurate
                information or failure to use the money for the submitted bills will be a violation of Christian Healthcare Ministries’ Guidelines
                (<a href="https://chministries.org/chm-guidelines">CHMinistries.org/chm-guidelines</a>).
              </p>
              <p>
                By signing below, I attest that the participating adult members included in my membership are Christians living by New
                Testament principles, who embrace the CHM Statements of Beliefs, attend group worship regularly (health permitting), follow
                scriptural teaching with regard to alcohol, and abstain from use of any form of tobacco, nicotine, or illegal drugs. I also attest that
                all information provided herein is true to the best of my knowledge.
              </p>

              
              <span>Member Name:</span>
              <TextInputGroup field={'consentMemberName'}
                value={data.consentMemberName || ''}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'consentMemberName')} />

              <p></p>
              <span>Member Signature:</span>
              <SignatureCanvas
                data={useMemo(() => data?.signatureData, [])}
                onChange={useCallback((sigData: string | undefined) => {
                  changeData('signatureData', sigData)
                }, [changeData])}/>

              <p></p>
              <TextInputGroup field={'consentDate'}
                label="Date:"
                value={data.consentDate || ''}
                inputType="date"
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'consentDate')} />
              
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
  </>
}
