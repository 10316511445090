import { AppAction, RootState } from "../store"
import { present } from "../../../lib/util/present"
import { uniqBy } from "lodash"
import { createToDosForExpense } from "../../../lib/todos/expense"
import { createToDosForIncident } from "../../../lib/todos/incident"
import { createToDosForSubmission } from "../../../lib/todos/submission"
import { TodoDependency, VirtualToDo, raiseUnknownRecordType } from "../../../lib/todos/types"
import { createToDosForAdvance } from "../../../lib/todos/advance"

/**
 * Given the current Redux state, returns a list of all of the incomplete ToDos
 */
export function selectToDos(s: RootState): VirtualToDo[] {
  const todos: VirtualToDo[] = []
  for (const expense of s.expenses.expenses) {
    todos.push(...createToDosForExpense(s, expense))
  }
  for (const incident of s.incidents.incidents) {
    todos.push(...createToDosForIncident(s, incident))
  }
  for (const submission of s.submissions.submissions) {
    todos.push(...createToDosForSubmission(s, submission))
  }
  // Add todos for advances
  for (const advance of (s.advances?.advances || [])) {
    todos.push(...createToDosForAdvance(s, advance))
  }
  return todos
}

export function selectToDosForExpense(expenseId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const expense = s.expenses.expenses.find((e) => e.id === expenseId)
    if (!expense) { return [] }

    const expenseTodos = Array.from(createToDosForExpense(s, expense))

    return expenseTodos
  }
}

export function selectToDosForIncident(incidentId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const incident = s.incidents.incidents.find((i) => i.id === incidentId)
    if (!incident) { return [] }

    const incidentTodos: VirtualToDo[] = Array.from(createToDosForIncident(s, incident))

    return incidentTodos
  }
}

export function selectToDosForSubmission(submissionId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const submission = s.submissions.submissions.find((s) => s.id === submissionId)
    if (!submission) { return [] }

    const submissionTodos: VirtualToDo[] = Array.from(createToDosForSubmission(s, submission))

    return submissionTodos
  }
}

export function selectToDosForAdvance(advanceId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const advance = s.advances?.advances?.find((a) => a.id === advanceId)
    if (!advance) { return [] }

    return Array.from(createToDosForAdvance(s, advance))
  }
}

/**
 * Given a list of TODOs, create a selector which returns all of the TODOs that the given todos depend on.
 */
export function selectDependentTodos(..._todoList: Array<VirtualToDo | null | undefined>): (s: RootState) => VirtualToDo[] {
  const todoList = _todoList.filter(present)

  return (s: RootState) => {
    const seenTodos = new Set(todoList.map((t) => t.key))
    const dependentTodos: VirtualToDo[] = []
    let newDependencies: TodoDependency[] = todoList.filter((t => t.dependsOn?.length)).flatMap((t) => t.dependsOn!)

    // Keep resolving as long as we have TODOs
    while(newDependencies.length > 0) {
      const currentDependencies = newDependencies
      const newTodos: VirtualToDo[] = []

      // Add in the todos for each dependency
      for (const dep of currentDependencies) {
        switch(dep.record_type) {
          case 'expense': {
            const expense = s.expenses.expenses.find((e) => e.id === dep.record_id)
            if (expense) {
              newTodos.push(...createToDosForExpense(s, expense))
            }
            break
          }
          case 'incident': {
            const incident = s.incidents.incidents.find((i) => i.id === dep.record_id)
            if (incident) {
              newTodos.push(...createToDosForIncident(s, incident))
            }
            break
          }
          case 'submission': {
            const submission = s.submissions.submissions.find((s) => s.id === dep.record_id)
            if (submission) {
              newTodos.push(...createToDosForSubmission(s, submission))
            }
            break
          }
          case 'advance': {
            const advance = s.advances?.advances?.find((a) => a.id === dep.record_id)
            if (advance) {
              newTodos.push(...createToDosForAdvance(s, advance))
            }
            break
          }
          default:
            raiseUnknownRecordType(dep.record_type)
        }
      }

      // Add them to our list
      dependentTodos.push(...newTodos)
      newTodos.forEach((t) => seenTodos.add(t.key))
      // And get the next set of dependencies that we haven't already seen
      newDependencies = newTodos.filter((t => t.dependsOn?.length))
        .flatMap((t) => t.dependsOn!)
        .filter((d) => !seenTodos.has(d.key))
    }

    return uniqBy(dependentTodos, (t) => t.key)
  }
}
