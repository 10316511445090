
export function isInstalledApp() {
  if (typeof navigator === 'undefined' || !navigator.userAgent) {
    return false;
  }

  // Check if the device is iOS
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

  // Check if the device is Android
  const isAndroid = /Android/.test(navigator.userAgent);

  let isInstalled = false;

  if (isIOS) {
    // Check if the app is running in standalone mode or as a TWA
    isInstalled = window.matchMedia('(display-mode: standalone)').matches ||
                  !/Safari/.test(navigator.userAgent);
  } else if (isAndroid) {
    // Check if the app is running in standalone mode or as a TWA
    isInstalled = window.matchMedia('(display-mode: standalone)').matches ||
                  document.referrer.includes('android-app://');
  }

  return isInstalled;
}
