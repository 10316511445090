import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxToolkit"
import { formatDateInTimeZone } from "../../../../lib/formatDateInTimeZone"
import { AttachmentModel } from "../../../reduxToolkit/attachmentsSlice"
import { ExpenseModel } from "../../../reduxToolkit/expensesSlice"
import { useCallback, useEffect, useState } from "react"
import { useUser } from "../../../hooks/useUser"
import { requireIncident } from "../../../wrappers/requireIncident"
import { RequireSubmissionProvided } from "../../../wrappers/requireSubmission"
import { completeSubmission } from "../../../reduxToolkit/actions/completeSubmission"
import { isSubmitted } from "../../../reduxToolkit/submissionsSlice"
import { usePendingExpenses } from "../../../hooks/usePendingExpenses"
import { Checklist } from "../../../components/checklist"
import { useLoadBlobs } from "../../../hooks/useLoadBlobs"
import { formatCurrency } from "../../../../lib/formatCurrency"
import { BlobFileBrowser } from "../../../components/blobFileBrowser"

import './submitToHra.scss'
import { useCustomization } from "../../../hooks/useCustomizations"

interface SubmitToHRAProps extends RequireSubmissionProvided {
}

export function SubmitToHRA({submission}: SubmitToHRAProps) {
  const dispatch = useAppDispatch()
  const user = useUser()
  const navigate = useNavigate()
  
  const hra = useCustomization('hra')

  // Redirect if already submitted
  useEffect(() => {
    if (isSubmitted(submission)) {
      navigate(`/incidents/${submission.incident_id}`)
    }
  }, [submission, navigate])

  const incident = submission.incident

  const pendingExpenses = usePendingExpenses(submission)
  const [canSubmit, setCanSubmit] = useState(false)

  const allAttachmentRecords = [submission.id, submission.incident_id, ...(submission.expense_ids || []), ...pendingExpenses.map((e) => e.id)]
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => allAttachmentRecords.includes(a.record_id)))
  const [blobs, { loading: blobsLoading }] = useLoadBlobs(attachments.map((a) => a.blob_key))
  
  if (!hra?.hasHra) {
    throw new Error('This page should not be shown if the HRA is not enabled')
  }

  const finishSubmission = useCallback(() => {
    dispatch(completeSubmission({
      submission: submission,
      blobs: attachments.map((attachment) => ({ key: attachment.blob_key })),
      pendingExpenses: pendingExpenses,
      submittedByUserId: user?.id || '',
    }))
  }, [attachments, dispatch, pendingExpenses, submission, user?.id])

  const cancelSubmission = useCallback(() => {
    const clickedOK = window.confirm('You haven\'t submitted all expenses. Are you sure you want to cancel?\n' +
      'Click "OK" to stay here and "Cancel" to leave this page.')

    if (!clickedOK) {
      navigate(`/incidents/${incident.id}`)
    }
  }, [incident.id, navigate])

  return <div className="row submit-to-hra">
    <div className="col-12">
      <button type="button" className="btn-close float-end" aria-label="Close"
        onClick={cancelSubmission}></button>
      <h2>Submit to HRA</h2>

      <p>
        <a href={hra.hraUrl} target="_blank" rel="noreferrer">
          Open this link to submit your claim.
        </a>
      </p>
    </div>
      
    <div className="submit-to-hra__files col-12 col-lg-6">
      <h4>Files</h4>
      {!blobsLoading && <BlobFileBrowser files={{ '/': blobs || [] }}
        settings={{ initialView: 'list', hideNavigation: true }}
        />}
    </div>

    <div className="submit-to-hra__checklist col-12 col-lg-6">
      <h4>Checklist</h4>
      <Checklist
        onChange={({completed}) => {
          setCanSubmit(completed)
        }}
        items={pendingExpenses.map((expense) => {
          return <LineItem key={expense.id} expense={expense} attachments={attachments} />
          })}
        />
    </div>
    
    <div className="col-12 col-lg-6 d-none d-lg-block">
      <small><i>In the "Actions" menu, you can download a zip file of all these receipts.</i></small>
    </div>
    <div className="col-12 col-lg-6">
      <p>
        <Link to={`/incidents/${incident.id}`} className={`btn btn-primary`}
          onClick={finishSubmission}>I've submitted my documents</Link>
      </p>
          
      {!canSubmit &&
        <p>
          Check off each of the above expenses as you submit them to the HRA.<br/>
          
        </p>}
    </div>
  </div>
}

export default requireIncident(SubmitToHRA)

function LineItem({expense, attachments}: {expense: ExpenseModel, attachments: AttachmentModel[] }) {
  const { date, paidAmount } = expense

  return <div className="submit-to-hra__line-item">
      <h5>
        {date ? formatDateInTimeZone(date, { format: 'MM/dd/yyyy' }) : 'Unknown Date'}
        &nbsp;-&nbsp;
        {paidAmount ? formatCurrency(paidAmount) : 'Unknown Amount'}
      </h5>
  </div>
}
