import { useUser } from "../hooks/useUser"
import { useAppSelector } from '../hooks/reduxToolkit'
import { Unauthorized } from '../screens/unauthorized'
import { BlobFileBrowser } from "../components/blobFileBrowser"
import { BlobRow } from "../../types/supabase"
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone"
import { useLoadBlobs } from "../hooks/useLoadBlobs"
import { useNavigate, useParams } from "react-router"

import './files.scss'

export function Files() {
  const params = useParams()
  const user = useUser()
  const navigate = useNavigate()

  const expenses = useAppSelector((s) => s.expenses.expenses)
  const incidents = useAppSelector((s) => s.incidents.incidents)
  const submissions = useAppSelector((s) => s.submissions.submissions)
  const attachments = useAppSelector((s) => s.attachments.attachments)

  const [blobs, { loading, error }] = useLoadBlobs()

  const attachedBlobs: Record<string, BlobRow[]> = {}
  const remainingBlobs = (blobs || []).slice()

  const currentRoute = '/' + params['*']

  for(const attachment of attachments) {
    const blob = blobs?.find(b => b.key === attachment.blob_key)
    if (!blob) { continue }

    let folder: string | undefined = undefined
    switch(attachment.table_name) {
      case 'incidents':
        const incident = incidents.find((i) => i.id == attachment.record_id)
        if (!incident) { continue }
        folder = `/incidents/${incident.start_date} ${incident.description}`
        break;

      case 'expenses':
        const expense = expenses.find((i) => i.id == attachment.record_id)
        if (!expense) { continue }
        folder = `/expenses/${expense.date} ${expense.provider}`
        break;

      case 'submissions':
        const submission = submissions.find((i) => i.id == attachment.record_id)
        if (!submission) { continue }
        const submissionIncident = incidents.find((i) => i.id == submission.incident_id)
        if (!submissionIncident) { continue }

        let submitted_at = 'pending'
        if (submission.submitted_at) {
          submitted_at = formatDateInTimeZone(submission.submitted_at, { format: 'yyyy-MM-dd' })
        }
        folder = `/incidents/${submissionIncident.start_date} ${submissionIncident.description}/submissions/${submitted_at}`
        break;

    }

    if (folder) {
      attachedBlobs[folder] ||= []
      attachedBlobs[folder].push(blob)
      const idx = remainingBlobs.indexOf(blob)
      if (idx >= 0) {
        remainingBlobs.splice(idx, 1)
      }
    }
  }

  if (!user) {
    return <Unauthorized />
  }

  return <div className="row files">
    <div className="col-12">
      <h1>File Archive</h1>
      {error && <div className="alert alert-danger">{error.message}</div>}
      {!loading &&
        <BlobFileBrowser files={{
            ...attachedBlobs,
            '/uploads/': remainingBlobs
          }}
          folder={currentRoute}
          settings={{
            initialView: currentRoute === '/' ? 'grid' : 'list',
          }}
          onFolderChange={(folder) => {
            navigate(`/files/${folder.replace(/^\/+/, '')}`)
          }}
          />}
    </div>
  </div>
}
