import { AdvanceModel } from "../advancesSlice"
import { RootState } from "../store"
import { SubmissionModel, isSubmitted } from "../submissionsSlice"

/**
 * This selector returns all CHM submissions that have any of the same expense ids as the advance.
 * @param advance 
 * @returns 
 */
export function selectChmSubmissionsCoveredByAdvance(advance: AdvanceModel): (state: RootState) => SubmissionModel[] {
  return (s) => s.submissions.submissions
    .filter(submission => 
      submission.incident_id === advance.incident_id && 
      (submission.submission_type === 'CHM' || submission.submission_type === 'CHM-addon')
    )
    .filter((submission) => {
      // Find which submissions have any of the same expense ids as the advance
      return submission.expense_ids?.some(id => advance.expense_ids.includes(id)) || false
    })
}
