import { formatDateInTimeZone } from "../../../../../../lib/formatDateInTimeZone";
import { assert } from "../../../../../../lib/util/assert";
import { isMobile } from "../../../../../../lib/util/isMobile";
import { slugify } from "../../../../../../lib/util/slugify";
import { BlobFileBrowser } from "../../../../../components/blobFileBrowser";
import { Checklist } from "../../../../../components/checklist";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxToolkit";
import { useLoadBlobs } from "../../../../../hooks/useLoadBlobs";
import { useUser } from "../../../../../hooks/useUser";
import { useFeature } from "../../../../../providers/featureFlags";
import { completeSubmission } from "../../../../../reduxToolkit/actions/completeSubmission";
import { SubmissionById } from "../../../../../reduxToolkit/selectors/submissionById";
import { isSubmissionData2024 } from "../../../../../reduxToolkit/submissionsSlice";
import { EmailMethodTab } from "../emailMethodTab";
import { useResetSubmission } from "../../../../../hooks/useResetSubmission";
import { Link, useLocation, useNavigation } from "react-router-dom";
import { useState } from "react";
import { useClient } from "../../../../../providers/supabase";
import { useAsyncAction } from "../../../../../hooks/useAsyncAction";

interface SubmissionComplete2024Props {
  submission: SubmissionById
  
  onSubmitted: () => void
}

export function SubmissionNextSteps2024({submission, onSubmitted}: SubmissionComplete2024Props) {
  const data = submission.data
  if (!isSubmissionData2024(data)) { throw new Error('Invalid submission data: ' + (data as any)?._version) }
  if (data.type !== 'CHM' && data.type !== 'addon') { throw new Error('Invalid submission type: ' + data.type) }

  const dispatch = useAppDispatch()
  const user = useUser()

  const pendingExpenseIDs = data.medicalBillWorksheetData.expenseRows.map((r) => r.expenseId)
  const pendingExpenses = useAppSelector((s) => s.expenses.expenses.filter((e) => pendingExpenseIDs.includes(e.id)))
  const allAttachmentRecords = [submission.id, submission.incident_id, ...(submission.expense_ids || []), ...pendingExpenseIDs]
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => allAttachmentRecords.includes(a.record_id)))

  const [blobs, loadBlobsState] = useLoadBlobs(attachments.map((a) => a.blob_key))

  const finishSubmission = () => {
    dispatch(completeSubmission({
      submission: submission,
      blobs: blobs || [],
      pendingExpenses: pendingExpenses,
      submittedByUserId: user?.id || '',
    }))
      .finally(() => {
        onSubmitted()
      })
  }

  const [resetSubmissionState, resetSubmission] = useResetSubmission(submission)
  
  const zipFileName = `${formatDateInTimeZone(submission.incident.start_date, { format: 'yyyy-MM-dd' })}_${slugify(submission.incident.description || '')}`
  
  const emailTabShown = useFeature('share_chm_submission')
  const mobile = isMobile()
  const canShare = typeof navigator.share === 'function'
  const emailTabShownFirst = emailTabShown && mobile && canShare
  
  if (loadBlobsState.loading) {
    return <div className="row submit-to-chm-2023">
      <div className="col-12">
        <h2>Upload to CHM</h2>

        <>
          <p>Loading your PDFs...</p>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      </div>
    </div>
  }
  assert(blobs, 'blobs should be loaded at this point')
  
  return <div className="submitToChm2024-SubmissionComplete row">
    <div className="col-12">
      {data.type === 'CHM' ?
        <h2>Upload to CHM</h2> :
        <h2>Upload Addon to CHM</h2>}
        
      <p>
        We've filled out your documents! You can download them below.
      </p>
      <p>
        If you need documents from previous submissions for this incident,<br />
        <Link target="_blank" rel="noopener noreferrer"
            to={`/files/incidents/${submission.incident.start_date} ${submission.incident.description}/submissions`}>
          you can find them here.
        </Link>
      </p>
    </div>
    <div className="col-12 col-lg-6 submit-to-chm-2024__file-browser">
      <BlobFileBrowser
        settings={{
          hideNavigation: true,
          initialView: 'list',
        }}
        rootFolderName={zipFileName}
        files={{
          '/': blobs
          }} />
    </div>
    <div className="col-12 col-lg-6 submit-to-chm-2024__next-steps">
      <ul className="nav nav-tabs" id="myTab" role="tablist" style={!emailTabShown ? { display: 'none' } : {}}>
        <li className="nav-item" role="presentation">
          <button id="chm-portal-tab"
            className={`nav-link ${!emailTabShownFirst && 'active'}`}
            data-bs-toggle="tab" data-bs-target="#chm-portal-tab-pane" type="button" role="tab"
            aria-controls="home-tab-pane" aria-selected="true">
              Browser Method
            </button>
        </li>
        {emailTabShown && <li className="nav-item" role="presentation">
                
          <button id="email-tab"
            className={`nav-link ${emailTabShownFirst && 'active'}`}
            data-bs-toggle={'tab'} data-bs-target="#email-tab-pane" type="button" role="tab"
            aria-controls="profile-tab-pane" aria-selected="false">
              Email Method
          </button>
        </li>}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className={`tab-pane ${!emailTabShownFirst && 'show active'}`} id="chm-portal-tab-pane" role="tabpanel" aria-labelledby="chm-portal-tab" tabIndex={0}>
          <BrowserMethod submission={submission} emailTabShown={emailTabShown} />
        </div>
        {emailTabShown && <div className={`tab-pane ${emailTabShownFirst && 'show active'}`} id="email-tab-pane" role="tabpanel" aria-labelledby="email-tab" tabIndex={1}>
          <EmailMethodTab incident={submission.incident} blobs={blobs} zipFileName={zipFileName} />
        </div>}
      </div>
    </div>

    <div className="col-12 mt-2">
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-6">
          <button onClick={finishSubmission}
              className={`btn w-100 btn-primary`}>
            I've uploaded my documents to CHM
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-lg-6 offset-lg-6">
          <button onClick={resetSubmission}
              disabled={resetSubmissionState.loading}
              className={`btn w-100 btn-outline-danger ${resetSubmissionState.loading && 'disabled'}`}>
            Delete Forms and Start Over
          </button>
        </div>
      </div>
    </div>
  </div>
  
}

function BrowserMethod({ submission, emailTabShown }: { submission: SubmissionById, emailTabShown?: boolean }) {
  const [view, setView] = useState<'desktop' | 'mobile'>(isMobile() ? 'mobile' : 'desktop')
  
  const nav = useLocation()
  const client = useClient()
  
  const [emailMeState, emailMe] = useAsyncAction(async () => {
    const origin = typeof window !== 'undefined' ? window.location.origin : 'https://app.healthsharetech.com'
    const url = new URL(nav.pathname, origin)
    url.hash = nav.hash
    url.search = nav.search
    
    const { error } = await client.functions.invoke('email-myself', {
      body: JSON.stringify({
        subject: `Your Requested Submission Link`,
        html: `
          <p>
          You requested we send you a direct link to your in-progress submission, so that you can continue on your computer.
          Here is your link for this incident:
          </p>
          <p>
          ${submission.incident.description} on ${formatDateInTimeZone(submission.incident.start_date, { format: 'yyyy-MM-dd' })}
          </p>
          <a href="${url.href}">${url.href}</a>
        `
      })
    })
    if (error) { throw new Error(error.message) }
    
    return true
  }, [nav, client, submission])
  
  if (view == 'desktop') {
    return <>
        <h4>Next Steps:</h4>
          <p>
          <Checklist
            items={[
              <span>
                Download all the files to your computer.<br/>
                <small><i>You can download a zip file in the "Actions" menu</i></small>
              </span>,
              <span>
                Log In to the Membership portal, find the "Sharing" drop-down, and click "Submit Medical Bills" <br/>
                <a href="https://portal.chministries.org/Needs/SubmitNeed" target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
                  https://portal.chministries.org/Needs/SubmitNeed</a>
              </span>,
              <span>
                Click on "Upload Printed PDFs"
              </span>,
              <span>
                For each PDF, click "Browse" and select the file from your "Downloads" folder.
              </span>,
              <span>
                For each itemized receipt in your files, click "Itemized Bill" and browse to the correct file.
              </span>
            ]} />
        </p>
      </>
  } else {
    return <>
      <p>
        The CHM portal doesn't work well on mobile devices.
        We can email you a link to this submission so you can continue
        on your computer.
        <br />
        {emailMeState.data ?
          <>
            <br />
            <span className="text-success">Email sent!</span> Check your inbox for a message from us.<br/>
            Didn't get it? <button onClick={emailMe} className={`btn btn-link ${emailMeState.loading && 'disabled'}`}>
              Send the email again
            </button>
          </> :
          <button onClick={emailMe} className={`btn btn-link ${emailMeState.loading && 'disabled'}`}>
            Email Me a Link to this Page
          </button>}
        {emailMeState.error && <p className="text-danger">{emailMeState.error.message}</p>}
      </p>
      {emailTabShown && <p>
        The other option is to use the Email Method by clicking on the other tab above.
      </p>}
    </>
  }
}
