import React, { ReactHTML, useEffect, useRef } from "react"
import * as  bootstrap from 'bootstrap'
import { present } from "../../lib/util/present"

interface TooltipProps<TElement extends keyof ReactHTML = 'span'> extends React.HTMLAttributes<ReactHTML[TElement]> {
  tooltip: string | null | false | undefined,
  placement?: 'top' | 'left' | 'right'
  
  className?: string
  
  htmlElement?: TElement
}

export function Tooltip<TElement extends keyof ReactHTML = 'span'>({
  children,
  className,
  placement,
  tooltip,
  htmlElement,
  ...props
}: React.PropsWithChildren<TooltipProps<TElement>>) {
  const ref = React.useRef<HTMLElement>(null)
  const tooltipInstance = useRef<bootstrap.Tooltip | null>(null)

  useEffect(() => {
    if (!present(tooltip)) { return }
    if (!ref.current) { return }

    tooltipInstance.current = new bootstrap.Tooltip(ref.current, {
    })
    const handle = () => { tooltipInstance.current?.hide() }
    document.addEventListener('click', handle)

    return () => {
      tooltipInstance.current?.dispose()
      document.removeEventListener('click', handle)
    }
  }, [tooltip])
  
  useEffect(() => {
    if (!tooltipInstance.current) { return }
    if (!present(tooltip)) { return }
    
    // Update the tooltip text
    ref.current?.setAttribute('data-bs-original-title', tooltip)
  }, [tooltip])
  
  if (!present(tooltip)) {
    return React.createElement(htmlElement || 'span', {ref, className, ...props}, children)
  }

  return React.createElement(
    htmlElement || 'span',
    {
      ref,
      className,
      'data-bs-toggle': 'tooltip',
      'data-bs-placement': placement || 'top',
      title: tooltip,
      ...props,
      onClick: (e: React.MouseEvent<ReactHTML[TElement]>) => {
        tooltipInstance.current?.toggle()
        if (props.onClick) {
          props.onClick(e)
        } else {
          e.preventDefault()
        }
      }
    },
    children || <i className="material-icons">help</i>)
}
