import { CombinedState, Middleware } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { addExpense, deleteExpense, ExpenseModel, isExpensesSliceAction, updateExpense } from "../expensesSlice";
import { updateIncident } from "../incidentsSlice";
import exp from "constants";


/**
 * This middleware ensures the Incident Start Date is updated whenever an expense is added, updated, or deleted.
 */
export const incidentStartDateMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (!isExpensesSliceAction(action)) {
    return next(action);
  }
  
  switch(action.type) {
    case deleteExpense.type:
      const previousExpense = store.getState().expenses.expenses.find((e) => e.id === action.payload.id);
      if (previousExpense?.incident_id) {
        const newExpensesState = store.getState().expenses.expenses.filter((e) => e.incident_id === previousExpense.incident_id);
        // splice out the deleted expense
        const i = newExpensesState.findIndex((e) => e.id === action.payload.id);
        if (i >= 0) {
          newExpensesState.splice(i, 1);
        }
        
        maybeUpdateStartDate(store, previousExpense.incident_id, newExpensesState);
      }
      
      return next(action);
      
    case addExpense.type:
      if (action.payload.incident_id) {
        const newExpensesState = store.getState().expenses.expenses.filter((e) => e.incident_id === action.payload.incident_id);
        newExpensesState.push(action.payload);
        
        maybeUpdateStartDate(store, action.payload.incident_id, newExpensesState);
      }
      return next(action);
      
    case updateExpense.type:
      // continue below
      break;
      
    default:
      return next(action);
  }
  
  // Did the action change either the incident ID or the date?
  const incident_id = 'incident_id' in action.payload ? action.payload.incident_id : null;
  const previousState = store.getState().expenses.expenses.find((e) => e.id === action.payload.id);
  
  // If we changed the incident ID, we need to update the start date of the old incident and the new incident
  if ('incident_id' in action.payload && incident_id && previousState?.incident_id !== action.payload.incident_id) {
    // update the start date of the new incident
    const newExpensesState = store.getState().expenses.expenses.filter((e) => e.incident_id === incident_id);
    newExpensesState.push({
      ...previousState,
      ...action.payload,
    } as ExpenseModel);
    
    maybeUpdateStartDate(store, incident_id, newExpensesState);

    if (previousState?.incident_id) {
      const newExpensesState = store.getState().expenses.expenses.filter((e) => e.incident_id === previousState.incident_id);
      // splice out the old expense that got moved to the new incident
      const i = newExpensesState.findIndex((e) => e.id === action.payload.id);
      if (i >= 0) {
        newExpensesState.splice(i, 1);
      }
      
      // update the start date of the old incident (if it exists - this could be a create action.)
      maybeUpdateStartDate(store, previousState.incident_id, newExpensesState);
    }
    
  // If we just changed the date, we may need to update the start date of the incident if it is attached
  } else if ('date' in action.payload && action.payload.date && previousState?.date !== action.payload.date) {
    if (previousState?.incident_id) {
      const newExpensesState = store.getState().expenses.expenses.filter((e) => e.incident_id === previousState.incident_id);
      // replace or add the new expense
      const i = newExpensesState.findIndex((e) => e.id === action.payload.id);
      if (i >= 0) {
        newExpensesState[i] = {
          ...previousState,
          ...action.payload,
        } as ExpenseModel;
      } else {
        newExpensesState.push({
          ...previousState,
          ...action.payload,
        } as ExpenseModel);
      }

      maybeUpdateStartDate(store, previousState.incident_id, newExpensesState);
    }
  }
  
  return next(action);
}

type Store = {
  dispatch: (action: any) => void,
  getState: () => RootState
}

function maybeUpdateStartDate(store: Store, incidentId: string, newExpensesState: Array<ExpenseModel>) {
  const incident = store.getState().incidents.incidents.find((i) => i.id === incidentId);
  if (!incident) {
    return;
  }
  // For maternity incidents, the start date is 40 weeks before the due date, not the earliest expense date
  if (incident.is_maternity) {
    return
  }
  // For add-on incidents where the initial incident was not tracked, we allow the user to set the start date.
  if (incident.is_addon_incident) {
    return
  }

  const startDate = newExpensesState.reduce((min, e) => {
    if (e?.date) {
      if (!min || e.date < min) {
        return e.date;
      }
    }
    return min;
  }, null as string | null);
  
  if (!startDate || startDate === incident.start_date) {
    return;
  }
  
  // Update the incident start date
  const now = new Date().toISOString();
  store.dispatch(updateIncident({
    id: incidentId,
    start_date: startDate,
    updated_at: now,
  }))
  
  return true
}
