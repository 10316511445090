import { useEffect, useMemo, useState } from "react";
import { present } from "../../../lib/util/present";
import { useValidatePromoCode } from "../../hooks/useValidatePromoCode";
import { Link, useNavigate } from "react-router-dom";
import { isInstalledApp } from "../../../lib/util/isInstalledApp";

export function PromoRedemption() {
  const isInsidePWA = useMemo(() => {
    return isInstalledApp();
  }, [])
  
  const [promoCode, setPromoCode] = useState('')
  useEffect(() => {
    // Detect whether the incoming URL has a promo code query param
    const url = new URL(window.location.href)
    const promoCode = url.searchParams.get('c')
    if (present(promoCode)) {
      setPromoCode(promoCode)
    }
  }, [])
  
  return <div className='row mt-4 justify-content-center'>
    <div className='col-12 col-lg-6'>
      <h1>Welcome to the Healthshare Reimbursement Tracker!</h1>
      
      <div>
        <p>With the Healthshare app, your employees can:</p>
        <ul>
          <li>Manage expenses and incidents digitally</li>
          <li>Get reminders of important due dates</li>
          <li>Automatically prepare CHM forms for easy submission</li>
        </ul>
      </div>
      <p>
        You can also set configuration rules and in-app instructions to reduce confusion and prevent errors in CHM
        submissions.
      </p>
      
      {isInsidePWA ? <PwaText promoCode={promoCode} /> : <WebText promoCode={promoCode} />}
      
      <div className="row mt-4">
        &nbsp;
      </div>
    </div>
  </div>
}

function PwaText({ promoCode }: { promoCode: string | null | undefined }) {
  const [isValid] = useValidatePromoCode(promoCode)
  const navigate = useNavigate()
  
  useEffect(() => {
    if (promoCode && isValid) {
      // Store the promo code in local storage
      localStorage.setItem('promoCode', promoCode)
      // redirect to sign in
      navigate('/signIn')
    }
  }, [promoCode, isValid, navigate])
  
  return <>
    {present(promoCode) && isValid &&
      <p>
        <strong className="text-success">Your code is valid!</strong>
        <br/>
        This code grants you a free month-long trial of the HealthShare app.
        <br/>
        Your code will be automatically applied after you <Link to="/signIn">sign in to the app</Link>.
      </p>}
      
    {present(promoCode) && !isValid &&
      <p>
        <strong className="text-danger">Your code has expired or is not valid.</strong>
        <br/>
        Please try scanning your QR code again, or <Link to="/help">contact support for assistance</Link>.
      </p>}
  </>
}

function WebText({ promoCode }: { promoCode: string | null | undefined }) {
  const [isValid] = useValidatePromoCode(promoCode)
  
  useEffect(() => {
    if (promoCode && isValid) {
      // Store the promo code in local storage
      localStorage.setItem('promoCode', promoCode)
    }
  }, [promoCode, isValid])
  
  return <>
    {present(promoCode) && isValid &&
      <p>
        <strong className="text-success">Your code is valid!</strong>
        <br/>
        This code grants you a free month-long trial of the HealthShare app.
        <br/>
        After signing in to the app, enter the code <span className="badge bg-success">{promoCode.toUpperCase()}</span> to redeem your free trial.
      </p>}
      
      <div className="row">
        <div className="col-6">
          <a href="https://apps.apple.com/us/app/healthshare-track/id6472617090" target="_blank" rel="noreferrer">
            <img src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="apple app store badge"
              style={{width: '100%', height: '60px' }}/>
          </a>
        </div>
        <div className="col-6">
          <a href="https://play.google.com/store/apps/details?id=com.healthsharetech.app&pcampaignid=promo-app-store-redirect" target="_blank" rel="noreferrer">
            <img src="/google_play_en_badge_web_generic.png" alt="google play store badge"
              style={{width: '100%', height: '60px' }}/>
          </a>
        </div>
      </div>
      
      <div className="row mt-4">
        <div className="col-12">
          Or <Link to="/signIn">sign in to the app</Link>.
        </div>
      </div>
  </>
}
