import { useEffect, useState } from "react";
import { isSubmitted } from "../../../reduxToolkit/submissionsSlice";
import { requireIncident, RequireIncidentProvided } from "../../../wrappers/requireIncident";
import { formatDateInTimeZone } from "../../../../lib/formatDateInTimeZone";
import { formatCurrency } from "../../../../lib/formatCurrency";
import { Tooltip } from "../../../components/tooltip";
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxToolkit";
import { createAdvanceRequest, isPendingAdvanceRequest } from "../../../reduxToolkit/advancesSlice";
import { useUser } from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { addNotification } from "../../../reduxToolkit/notificationsSlice";
import toast from "react-hot-toast";

export function AdvanceRequestCreate({incident}: RequireIncidentProvided) {
  const chmSubmittedExpenseIDs = incident.submissions
    .filter(isSubmitted)
    .filter(submission => submission.submission_type === 'CHM' || submission.submission_type === 'CHM-addon')
    .flatMap(submission => submission.expense_ids)
    
  const hraSubmittedExpenseIDs = incident.submissions
    .filter(isSubmitted)
    .filter(submission => submission.submission_type === 'HRA')
    .flatMap(submission => submission.expense_ids)
    
  const alreadyAdvancedExpenseIDs = useAppSelector((s) => (s.advances?.advances || [])
    .flatMap(a => a.expense_ids))

  const [includedExpenseIDs, setIncludedExpenseIDs] = useState(
    chmSubmittedExpenseIDs.filter((id) => !alreadyAdvancedExpenseIDs.includes(id))
  )

  const totalAdvanceAmount = incident.expenses
    .filter(expense => includedExpenseIDs.includes(expense.id))
    .reduce((total, expense) => total + parseFloat(expense.paidAmount || '0'), 0)
    
  const dispatch = useAppDispatch()
  const currentUser = useUser()
  const navigate = useNavigate()
  
  
  // You can only have one open advance request at a time - if there's one, navigate to it
  const openAdvanceRequest = useAppSelector((s) => (s.advances?.advanceRequests || [])
    .filter((ar) => ar.incident_id === incident.id)
    .find(isPendingAdvanceRequest))
  useEffect(() => {
    if (openAdvanceRequest) {
      navigate(`/incidents/${incident.id}`)
      toast.error('You already have an open advance request for this incident.')
    }
  }, [])

  const submitRequest = () => {
    if (includedExpenseIDs.length === 0) {
      toast.error('You must select at least one expense to request an advance for.')
      return
    }
    
    const id = uuidv4()
    const now = new Date().toISOString()
    dispatch(createAdvanceRequest({
      id,
      created_at: now,
      updated_at: now,
      incident_id: incident.id,
      expense_ids: includedExpenseIDs,
      created_by_user_id: currentUser.id,
      membership_id: incident.membership_id,
      amount: totalAdvanceAmount.toFixed(2),
    }))
    
    // Send a notification to the plan administrators
    dispatch(addNotification({
      id: uuidv4(),
      created_at: now,
      updated_at: now,
      record_id: id,
      record_type: 'advance_request',
      notification_type: 'advance_request_created',
      membership_id: incident.membership_id,
      from_user_email: 'email' in currentUser ? currentUser.email : undefined,
    }))
    
    navigate(`/incidents/${incident.id}`)
  }

  return <div className="advance-request-create">
    <div className="row advance-request-create__header-row">
      <div className="col-12 advance-request-create__header">
        <h1>Request an Advance</h1>
      </div>
    
      <div className='col-12 col-lg-6 advance-request-create__info'>
        <p>
          <strong>Incident:</strong> {incident.description}<br/>
          <strong>Advance Amount:</strong>{' '}{formatCurrency(totalAdvanceAmount)}
        </p>
      </div>
    
    </div>
    
    <div className="row">
      <form onSubmit={(e) => {
        e.preventDefault()
        submitRequest()
      }}>
        <div className="col-12 mt-2">
          <h6>Which expenses do you need an advance for?</h6>
          
          <ul className="checklist advance-request-create__expenses mt-2">
            {incident.expenses.map(expense => {
              const {id, date, paidAmount, provider} = expense
              
              const isIncluded = includedExpenseIDs.includes(expense.id)
              
              let disabled = false
              let disabledReason = ''
              if (!isIncluded) {
                if (hraSubmittedExpenseIDs.includes(expense.id)) {
                  disabled = true
                  disabledReason = "You've already been reimbursed for this expense via your HRA."
                } else if (!expense.submitted_at) {
                  disabled = true
                  disabledReason = "You must first submit this expense to CHM before requesting an advance."
                } else if (alreadyAdvancedExpenseIDs.includes(expense.id)) {
                  disabled = true
                  disabledReason = "You've already been advanced for this expense."
                }
              }
              
              return <Tooltip key={expense.id} tooltip={disabledReason}
                  htmlElement='li'
                  className={`checklist__item ${disabled && 'disabled'}`}
                  onClick={() => {
                    if (disabled) { return }
                    if (isIncluded) {
                      setIncludedExpenseIDs(includedExpenseIDs.filter(id => id !== expense.id))
                    } else {
                      setIncludedExpenseIDs([...includedExpenseIDs, expense.id])
                    }
                  }}
                >
                  <div className="advance-request-create__checklist-item__description">
                    {date ? formatDateInTimeZone(date, { format: 'MM/dd/yyyy' }) : 'Unknown Date'}
                    &nbsp;-&nbsp;
                    {paidAmount ? formatCurrency(paidAmount) : 'Unknown Amount'}
                    <br />
                    <span>{provider}</span>
                    <br/>
                  </div>
                
                  <div className='advance-request-create__checklist-item__form-check-wrapper'>
                    <div className='form-check form-check-inline'>
                      <input className={`form-check-input ${disabled && 'disabled'}`}
                        type='checkbox'
                        disabled={disabled}
                        name={`${id}_included`} id={`${id}_included`}
                        value='included'
                        checked={isIncluded}></input>
                    </div>
                  </div>
                </Tooltip>
            })}
          </ul>
        </div>
        
        <div className="col-12 mt-2">
          <button className="btn btn-primary advance-request-create__submit">Submit Request for {formatCurrency(totalAdvanceAmount)}</button>
        </div>
      </form>
    </div>
  </div>
}

export default requireIncident(AdvanceRequestCreate);
