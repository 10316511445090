import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateIncident } from "../incidentsSlice";

interface MarkHasCalledCHMForPregnancyPayload {
  incident_id: string,
}

export const markHasCalledCHMForPregnancy = createAsyncThunk('incidents/markHasCalledCHMForPregnancy',
  async (payload: MarkHasCalledCHMForPregnancyPayload, {dispatch}) => {
    const { incident_id } = payload
    const now = new Date().toISOString()

    dispatch(updateIncident({
      id: incident_id,
      maternity_chm_call_completed_at: now,
      updated_at: now,
    }))
  })
