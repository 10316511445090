import { differenceInDays } from "date-fns";
import { isOpenAdvance, isPendingAdvanceRequest } from "../advancesSlice";
import { bySubmittedAtDescNullsFirst } from "../../../lib/util/sort";
import { IncidentWithExpensesAndSubmissions, selectIncidentsWithExpensesAndSubmissions } from "./selectIncidentsWithExpensesAndSubmissions";
import { RootState } from "../store";



/**
 * An incident is considered "open" if any of these conditions are true:
 * 1. There are no expenses yet
 * 2. There are unsubmitted expenses
 * 3. There are open advances
 * 4. There are pending advance requests
 * 5. The most recent submission is less than 30 days old
 */
export function isOpenIncident(i: IncidentWithExpensesAndSubmissions) {
  if (i.expenses.length === 0 ||
  i.expenses.some((e) => !e.submitted_at) ||
  i.advances.some(isOpenAdvance) ||
  i.advance_requests.some(isPendingAdvanceRequest)) {
    return true
  }
  const mostRecentSubmission = i.submissions.sort(bySubmittedAtDescNullsFirst)[0]
  if (mostRecentSubmission && (
      !mostRecentSubmission.submitted_at ||
      differenceInDays(new Date(), new Date(mostRecentSubmission.submitted_at)) < 30
  )) {
    return true
  }
  return false
}

/**
 * This selector returns all open incidents.
 * @see isOpenIncident for the definition of "open"
 */
export function selectOpenIncidents(state: RootState) {
  return selectIncidentsWithExpensesAndSubmissions(state).filter(isOpenIncident)
}

