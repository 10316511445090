import { AppSupabaseClient, MembershipRoleRow, MembershipRow, OrganizationRow, ProfileRow } from "../../types/supabase"

type SelectMembershipForUserResult =
  {
    role: MembershipRoleRow
    membership: MembershipRow,
    profile: ProfileRow,
    organization: OrganizationRow | null
  }

export async function selectMembershipForUser(
  client: AppSupabaseClient,
  {userId}: {
    userId: string
  }
): Promise<SelectMembershipForUserResult | null> {
  const resp = await client.from('membership_roles')
    .select(`*, memberships (*)`)
    .eq('user_id', userId)
    .limit(1)

  if (resp.error) { throw resp.error }
  const mRow = resp.data[0]
  if (!mRow) { return null }

  const resp2 = await client.from('profiles')
    .select('*')
    .eq('id', userId)
    .limit(1)

  if (resp2.error) { throw resp2.error }
  const pRow = resp2.data[0]
  if (!pRow) { return null }

  const orgResponse = await client.from('organization_memberships')
    .select(`*, organizations (*)`)
    .eq('membership_id', mRow.membership_id)
    .limit(1)
    .maybeSingle()
  
  if (orgResponse.error) { throw orgResponse.error }
  const oRow: OrganizationRow | null = orgResponse.data?.organizations || null
  
  const membership =
    Array.isArray(mRow.memberships) ? mRow.memberships[0] : mRow.memberships!
  return {
    role: mRow as MembershipRoleRow,
    membership,
    profile: pRow as ProfileRow,
    organization: oRow
  }
}
