import { useCallback, useState } from "react"
import {v4 as uuid4} from 'uuid'
import { useAppDispatch, useAppSelector } from "./reduxToolkit"
import { PendingSubmissionModel, SubmissionModel, UnsavedSubmissionModel, addSubmission, deleteSubmission, isPending, isUnsavedSubmissionModel, updateSubmission } from "../reduxToolkit/submissionsSlice"
import { IncidentModel } from "../reduxToolkit/incidentsSlice"
import { chooseSubmissionType } from "../../lib/rules/chooseSubmissionType"
import { SubmissionType } from "../../types/supabase"
import { ExpenseModel, isCompleteExpense } from "../reduxToolkit/expensesSlice"
import { useCustomization } from "./useCustomizations"
import { Customizations } from "../reduxToolkit/selectors/customizations"

export type PendingSubmissionUnion = PendingSubmissionModel | UnsavedSubmissionModel

/**
 * Builds a "pending" submission for the given incident, based on the submission type.
 * 
 * If the user had previously started a submission for this incident AND the submission type is still the same,
 * that PendingSubmissionModel will be returned.
 * 
 * Otherwise, a new UnsavedSubmissionModel will be initialized.
 * 
 * The createSubmission callback can be invoked to save the pending submission to the database.  This will assign it
 * an ID and allow us to navigate to the submission page.
 */
export function usePendingSubmission(incident: IncidentModel) {
  const dispatch = useAppDispatch()
  const hra = useCustomization('hra')

  const allExpenses = useAppSelector((s) => s.expenses.expenses.filter((e) => e.incident_id === incident.id))
  const submissions = useAppSelector((s) => s.submissions.submissions.filter((e) => e.incident_id === incident.id))

  const pendingSubmission = getOrBuildPendingSubmissionModel({
    incident,
    incidentSubmissions: submissions,
    incidentExpenses: allExpenses,
  }, {
    hra
  })
  const existingPendingSubmission = submissions.find(isPending)
  
  const [createdSubmissionId, setCreatedSubmissionId] = useState<string | null>(null)

  const createSubmission = useCallback((model: PendingSubmissionModel | UnsavedSubmissionModel): PendingSubmissionModel => {
    if (createdSubmissionId) {
      throw new Error("createSubmission was called more than once")
    }

    const now = new Date().toISOString()
    if (isUnsavedSubmissionModel(model)) {
      if (existingPendingSubmission) {
        // discard and restart the previous submission
        dispatch(deleteSubmission({
          id: existingPendingSubmission.id,
          updated_at: now,
          deleted_at: now
        }))
      }

      const id = uuid4()
      const newModel: PendingSubmissionModel = {
        ...model,
        id: id,
        created_at: now,
        updated_at: now
      }
      dispatch(addSubmission(newModel))
      setCreatedSubmissionId(id)
      return newModel
    } else {
      // We've already created this submission but we're re-creating the same one for some reason.
      // Just update it.
      const updatedModel = {
        ...model,
        updated_at: now
      }
      dispatch(updateSubmission(updatedModel))
      setCreatedSubmissionId(updatedModel.id)
      return updatedModel
    }
  }, [dispatch, existingPendingSubmission, createdSubmissionId])

  return [
    pendingSubmission,
    createSubmission
  ] as const
}

export function getOrBuildPendingSubmissionModel(
  {
    incident,
    incidentSubmissions,
    incidentExpenses,
  }: {
    incident: IncidentModel,
    incidentSubmissions: Array<SubmissionModel>,
    incidentExpenses: Array<ExpenseModel>,
  },
  customizations: Pick<Customizations, 'hra'>,
): PendingSubmissionModel | UnsavedSubmissionModel | null {
  const existingPendingSubmission: PendingSubmissionModel | undefined =
    incidentSubmissions.filter(isPending)[0]

  let pendingSubmission: PendingSubmissionModel | UnsavedSubmissionModel = existingPendingSubmission

  const submissionType: SubmissionType | null = chooseSubmissionType(
    {
      incident,
      incidentSubmissions: incidentSubmissions,
      incidentExpenses: incidentExpenses
    },
    customizations
  )
  
  if (!submissionType) { return null }
  

  if (!pendingSubmission || submissionType !== existingPendingSubmission.submission_type) {
    // If we haven't yet created the submission or we're changing the type, we will delete and recreate.
    pendingSubmission = buildPendingSubmissionModel(incident, submissionType)
  }

  return pendingSubmission
}

function buildPendingSubmissionModel(incident: IncidentModel, type: SubmissionType): UnsavedSubmissionModel {
  return {
    membership_id: incident.membership_id,
    incident_id: incident.id,
    submission_type: type
  }
}
