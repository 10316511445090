import { RootState } from "../../app/reduxToolkit/store"
import { VirtualToDo } from "./types"
import { AdvanceModel, indicateAdvanceRepaid } from "../../app/reduxToolkit/advancesSlice" // Assuming this exists
import { formatCurrency } from "../formatCurrency"
import { addDays, addWeeks, endOfYear, formatISO } from "date-fns"
import { parseDateInTimeZone } from "../formatDateInTimeZone"
import { isSubmitted } from "../../app/reduxToolkit/submissionsSlice"
import { selectChmSubmissionsCoveredByAdvance } from "../../app/reduxToolkit/selectors/selectChmSubmissionsCoveredByAdvance"
import { bySubmittedAtDesc } from "../util/sort"

export function * createToDosForAdvance(s: RootState, advance: AdvanceModel): Generator<VirtualToDo> {
  if (advance.repaid_at) {
    return
  }
  
  let checks: Array<(s: RootState, advance: AdvanceModel) => VirtualToDo | void> = [
    checkForRepayment
  ]
  
  for (const check of checks) {
    const todo = check(s, advance)
    if (todo) {
      yield todo
    }
  }
}

function checkForRepayment(s: RootState, advance: AdvanceModel): VirtualToDo | void {
  if (advance.repaid_at) { return }
  
  if (advance.indicated_repaid_at) {
    // If the advance has been indicated as repaid, but it's been more than 2 weeks and the repayment hasn't been confirmed, then show a TODO
    if (addWeeks(parseDateInTimeZone(advance.indicated_repaid_at), 2) < new Date()) {
      return {
        title: `Check whether your advance repayment was received`,
        key: `advance/${advance.id}/confirm-repayment`,
        record_id: advance.id,
        record_type: 'advance',
        todo_type: 'confirm-repayment',
        action: {
          type: 'navigate',
          route: `/incidents/${advance.incident_id}/advance/${advance.id}`,
        }
      }
    }

    return
  }
  
  const now = new Date().toISOString()
  
  const eoy = endOfYear(parseDateInTimeZone(now))
  let dueDate = eoy
  
  const chmSubmissionsForAdvance = selectChmSubmissionsCoveredByAdvance(advance)(s)
  const latestCHMSubmissionDate = chmSubmissionsForAdvance.filter(isSubmitted).sort(bySubmittedAtDesc).at(0)?.submitted_at
  if (latestCHMSubmissionDate) {
    // If the latest submission was less than 30 days ago, then don't show this TODO yet.
    if (parseDateInTimeZone(latestCHMSubmissionDate) > addDays(parseDateInTimeZone(now), -30)) {
      return
    }
    
    // If the latest CHM submission would be repaid after the end of the year, then use 120 days from the submission date
    const chmReimbursementDate = addDays(parseDateInTimeZone(latestCHMSubmissionDate), 120)
    if (chmReimbursementDate > eoy) {
      dueDate = chmReimbursementDate
    }
  }

  return {
    title: `Repay Advance of ${formatCurrency(advance.amount)}`,
    key: `advance/${advance.id}/repay`,
    record_id: advance.id,
    record_type: 'advance',
    todo_type: 'repay',
    dueDate: formatISO(dueDate, { representation: 'date' }),
    action: {
      type: 'navigate',
      route: `/incidents/${advance.incident_id}/advance/${advance.id}`,
    }
  }
}
