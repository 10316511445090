import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery'
import { Link } from 'react-router-dom'
import { SignOutButton } from './signOutButton'
import * as bootstrap from 'bootstrap'
import { useAppSelector } from '../hooks/reduxToolkit'
import { selectToDos } from '../reduxToolkit/selectors/todos'
import { byDueDateAscendingNullsLast } from '../../lib/util/sort'
import differenceInDays from 'date-fns/differenceInDays'
import { parseDateInTimeZone } from '../../lib/formatDateInTimeZone'
import { Tooltip } from './tooltip'

import './topNav.scss'

export function TopNav() {

  return <>
    <MobileTopBar />
    <nav className="top-nav navbar navbar-expand-lg">
      <DesktopNav />

      <MobileNav/>

    </nav>
  </>
}

function DesktopNav() {
  const currentRoute = useLocation().pathname

  return <div className="container-fluid d-none d-md-flex nav-desktop">
    <div className='navbar-brand'>
      <h2>HealthShare</h2>
    </div>

    <div className={'nav-item' + (currentRoute === '/' ? ' active' : '')}>
      <Link to='/' className='nav-link'>
        <i className='material-icons-outlined'>home</i>
        <span className='d-none d-md-inline'>Home</span>
      </Link>
    </div>

    <div className={'nav-item' + (currentRoute === '/expenses' ? ' active' : '')}>
      <Link to='/expenses' className='nav-link'>
        <i className='material-icons-outlined'>payments</i>
        <span className='d-none d-md-inline'>Expenses</span>
      </Link>
    </div>

    <div className={'nav-item' + (currentRoute === '/incidents' ? ' active' : '')}>
      <Link to='/incidents' className='nav-link'>
        <i className='material-icons-outlined'>sticky_note_2</i>
        <span className='d-none d-md-inline'>Incidents</span>
      </Link>
    </div>

    <TodoListNavItem />

    <div className='nav-item dropdown'>
      <button className="nav-link dropdown-toggle btn btn-link nav-desktop__more-button" data-bs-toggle="dropdown" aria-expanded="false">
        More
      </button>
      <ul className="dropdown-menu">
        <li><Link to="/profile" className='dropdown-item'>Profile</Link></li>
        <li><Link to="/settings" className='dropdown-item'>Settings</Link></li>
        <li><Link to="/changePassword" className='dropdown-item'>Change Password</Link></li>
        <li><Link to="/dependents" className='dropdown-item'>Dependents</Link></li>
        <li><Link to="/files" className='dropdown-item'>File Archive</Link></li>
        <li><a href="/admin" className='dropdown-item'>Plan Administrator</a></li>
        <li><Link to="/deleteAccount" className='dropdown-item'>Delete Account</Link></li>
        <li></li>
      </ul>
    </div>

    <div className='nav-item mt-auto'>
      <SignOutButton className='nav-link'>
        <i className='material-icons'>logout</i>
        Sign Out
      </SignOutButton>
    </div>
    <div className={'nav-item' + (currentRoute === '/help' ? ' active' : '')}>
      <Link to="help" className='nav-link'>
        <i className="material-icons d-none d-md-inline-block">help</i>
        <span className='d-none d-md-inline'>Help</span>
      </Link>
    </div>
  </div>
}

function MobileNav() {
  const currentRoute = useLocation().pathname
  
  useEffect(() => {
    const hideOffcanvasOnClick = (e: JQuery.ClickEvent) => {
      const offcanvasDiv = $(e.target).closest('.offcanvas')[0]
      const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasDiv)
      console.log('click', offcanvasDiv, offcanvas)
      offcanvas?.hide()
    }
    $(document).on('click', '.offcanvas .nav-link', hideOffcanvasOnClick)

    return () => {
      $(document).off('click', hideOffcanvasOnClick)
    }
  }, [])


  return <div className="container-fluid d-flex d-md-none nav-mobile">
    <div className={'nav-item' + (currentRoute === '/' ? ' active' : '')}>
      <Link to='/' className='nav-link'>
        <i className='material-icons-outlined'>home</i>
      </Link>
    </div>

    <div className={'nav-item' + (currentRoute === '/expenses' ? ' active' : '')}>
      <Link to='/expenses' className='nav-link'>
        <i className='material-icons-outlined'>payments</i>
      </Link>
    </div>

    <div className={'nav-item' + (currentRoute === '/incidents' ? ' active' : '')}>
      <Link to='/incidents' className='nav-link'>
        <i className='material-icons-outlined'>sticky_note_2</i>
      </Link>
    </div>

    <TodoListNavItem />

    <a className="nav-link" data-bs-toggle="offcanvas" href="#offcanvasMobileNav" role="button" aria-controls="offcanvasMobileNav">
      <i className='material-icons-outlined'>menu</i>
    </a>

    <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasMobileNav" aria-labelledby="offcanvasMobileNavLabel">
      <div className="offcanvas-header">
        <h2>HealthShare</h2>
        <a className='btn btn-link text-reset' href="#offcanvasMobileNav" data-bs-dismiss="offcanvas" aria-label="Close">
          <i className='material-icons-outlined'>close</i>
        </a>
      </div>
      <div className="offcanvas-body">
        <div className={'nav-item' + (currentRoute === '/profile' ? ' active' : '')}>
          <Link to="/profile" className='nav-link'>Profile</Link>
        </div>
        <div className={'nav-item' + (currentRoute === '/settings' ? ' active' : '')}>
          <Link to="/settings" className='nav-link'>Settings</Link>
        </div>
        <div className={'nav-item' + (currentRoute === '/changePassword' ? ' active' : '')}>
          <Link to="/changePassword" className='nav-link'>Change Password</Link>
        </div>
        <div className={'nav-item' + (currentRoute === '/dependents' ? ' active' : '')}>
          <Link to="/dependents" className='nav-link'>Dependents</Link>
        </div>
        <div className={'nav-item' + (currentRoute === '/files' ? ' active' : '')}>
          <Link to="/files" className='nav-link'>Archive</Link>
        </div>

        <div className='nav-item'>
          <Link to="/deleteAccount" className='nav-link'>Delete Account</Link>
        </div>

        <div className='nav-item mt-auto'>
          <SignOutButton className='nav-link'>
            <i className='material-icons'>logout</i>
            Sign Out
          </SignOutButton>
        </div>
        <div className={'nav-item' + (currentRoute === '/help' ? ' active' : '')}>
          <Link to="help" className='nav-link'>
            <i className="material-icons">help</i>
            Help
          </Link>
        </div>
      </div>
    </div>
  </div>
}

function TodoListNavItem() {
  const currentRoute = useLocation().pathname
  
  const todos = useAppSelector(selectToDos)
    .sort(byDueDateAscendingNullsLast)

  const firstTodoDueDate = todos.find((t) => t.dueDate)?.dueDate
  const dayDiff = firstTodoDueDate && differenceInDays(parseDateInTimeZone(firstTodoDueDate), new Date())
  const dueSoon = typeof(dayDiff) == 'number' && dayDiff <= 14

  const tooltip = typeof(dayDiff) == 'number' &&
    dayDiff > 0 ?
      `Next to-do due in ${dayDiff} days` :
      `You have something due today!`

  return <div className={`nav-item nav-item__todo-list ${currentRoute === '/todos' ? 'active' : ''}`}>
    <Link to='/todos' className='nav-link'>
      <i className='material-icons-outlined'>checklist</i>
      <span className='d-none d-md-inline'>Checklist</span>
      {todos.length > 0 &&
        <span className={`nav-item__todo-list__unread ${dueSoon && 'due-soon'}`}>
          {dueSoon ?
            <Tooltip tooltip={tooltip}>
              <i className="material-icons">warning</i>
            </Tooltip>:
            todos.length}
        </span>}
    </Link>
  </div>
}

function MobileTopBar() {
  let navigate = useNavigate();
  const location = useLocation();
  
  // We can only go back if we're not at a root route
  const canGoBack = ![ '/', '/expenses', '/incidents', '/todos' ].includes(location.pathname)
  
  return <div className="d-flex mobile-top-bar d-md-none">
    <button className={`btn btn-link mobile-top-bar__back-button ${canGoBack ? '' : 'd-none'}`} onClick={() => navigate(-1)}>
      <i className='material-icons-outlined'>arrow_back</i>
    </button>
  </div>
}
