import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SubmissionInsert, SubmissionUpdate, NotDeleted, SubmissionType, raiseUnknownSubmissionType } from '../../types/supabase'
import type { DeepPartialNullable, NotNull } from '../../types/util'
import { PURGE } from 'redux-persist';
import { redactObjectExceptKeys } from '../../lib/util/redact'
import { replaceOrInsert } from '../../lib/util/replaceOrInsert'
import { present } from '../../lib/util/present'
import { onSyncDownComplete } from './actions/onSyncDownComplete';

/**
 * The required fields for an submission model.
 *
 * The submission must:
 * - not be deleted
 * - have an ID
 * - have a created_at timestamp
 * - have an updated_at timestamp
 * - have a start_date
 */
type RequiredSubmissionFields = 'id' | 'updated_at' | 'created_at' | 'submission_type'

export type SubmissionInsertPayload = SubmissionModel

/**
 * The required fields for updating an existing submission.
 * Updating only requires us to set the values that we intend to update,
 *  but we must match the ID and provide a new updated_at timestamp.
 */
type SubmissionUpdatePayload = NotNull<NotDeleted<SubmissionUpdate>, 'id' | 'updated_at'>

/**
 * The local model of an Submission row, which may be a row from the server or a row that has been
 * inserted locally and not yet synced to the server.
 */
export type SubmissionModel = NotNull<NotDeleted<SubmissionInsert>, RequiredSubmissionFields>

export type SubmittedSubmissionModel = NotNull<SubmissionModel, 'submitted_at' | 'submitted_by_user_id' | 'expense_ids'>

export function isSubmitted(model: SubmissionModel): model is SubmittedSubmissionModel {
  return present(model.submitted_at)
}

export type PendingSubmissionModel = Omit<SubmissionModel, 'submitted_at' | 'submitted_by_user_id' | 'expense_ids'>

export function isPending(model: SubmissionModel): model is PendingSubmissionModel {
  return !present(model.submitted_at)
}

export type UnsavedSubmissionModel = Omit<PendingSubmissionModel, 'id' | 'created_at' | 'updated_at'> & {
  id?: null | undefined,
  created_at?: null,
  updated_at?: null
}

export function isUnsavedSubmissionModel(model: PendingSubmissionModel | UnsavedSubmissionModel): model is UnsavedSubmissionModel {
  return !present(model.id)
}

export type SubmissionsSliceState = {
  submissions: Array<SubmissionModel>

  lastLoadFromServer?: number
}

const initialState: SubmissionsSliceState = {
  submissions: [],
}

export const submissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    addSubmission(state, action: PayloadAction<SubmissionInsertPayload>) {
      state.submissions.push(action.payload)
    },
    updateSubmission(state, action: PayloadAction<SubmissionUpdatePayload>) {
      const i = state.submissions.findIndex((t) => t.id === action.payload.id)
      if (i < 0) {
        throw new Error(`Could not find submission with id ${action.payload.id}`)
      }

      // Replace the record with the updated values
      state.submissions[i] = {
        ...state.submissions[i],
        ...action.payload,
      }
    },
    deleteSubmission(state, action: PayloadAction<{ id: string, updated_at: string, deleted_at: string }>) {
      // Remove it out of the submissions array
      const i = state.submissions.findIndex((e) => e.id === action.payload.id)
      if (i >= 0) {
        state.submissions.splice(i, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    }).addCase(onSyncDownComplete, (state, action) => {

      for (const submission of action.payload.submissions?.updated || []) {
        replaceOrInsert(state.submissions, submission)
      }
      for (const deletedSubmission of action.payload.submissions?.deleted || []) {
        const i = state.submissions.findIndex((t) => t.id === deletedSubmission.id)
        if (i >= 0 && state.submissions[i].updated_at <= deletedSubmission.updated_at) {
          state.submissions.splice(i, 1)
        }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  addSubmission,
  updateSubmission,
  deleteSubmission
} = submissionsSlice.actions

export type SubmissionsSliceAction = ReturnType<typeof submissionsSlice.actions[keyof typeof submissionsSlice.actions]>

export function isSubmissionsSliceAction(action: any): action is SubmissionsSliceAction {
  return action.type?.startsWith(submissionsSlice.name)
}

export default submissionsSlice.reducer

/*
 * Redact all sensitive information from the expenses and submissions, so that they can be sent to Analytics tools like
 * Sentry or Amplitude.
 * Due to HIPAA compliance, we cannot send any patient information to these tools.
 * The redaction operates with a whitelist to ensure that we don't accidentally send any sensitive information in the future.
*/

export function redactSubmissions(state: SubmissionsSliceState): DeepPartialNullable<SubmissionsSliceState> {
  return {
    submissions: state.submissions?.map(redactSubmission),
  }
}

export function redactSubmission(submission: Partial<SubmissionModel>): DeepPartialNullable<SubmissionModel> {
  return {
    ...redactObjectExceptKeys(submission,
      'id', 'submission_type', 'created_at', 'updated_at', 'submitted_at', 'submitted_by_user_id',
      'membership_id', 'incident_id', 'expense_ids'),
  }
}

export function redactSubmissionsSliceAction(action: SubmissionsSliceAction) {
  switch(action.type) {
    case addSubmission.type:
    case updateSubmission.type:
      return {
        type: action.type,
        payload: redactSubmission(action.payload)
      }

    case deleteSubmission.type:
      return {
        type: action.type,
        payload: {
          id: action.payload.id,
          updated_at: action.payload.updated_at
        }
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

/**
 * Renders a friendly name indicating where the reimbursement request was submitted to.
 */
export function submittedToText(submission: SubmissionModel) {
  const submissionType = submission.submission_type as SubmissionType
  switch(submissionType) {
    case 'CHM':
    case 'CHM-addon':
      return 'CHM'
    case 'HRA':
      return 'HRA'
    case 'CHM-personal-responsibility':
      return 'HRA (Personal Responsibility)'
    default:
      raiseUnknownSubmissionType(submissionType)
  }
}

interface SubmissionData2023Base {
  _version: '2023-05-04'
}

export interface SubmissionData2023CHM extends SubmissionData2023Base {
  type: 'CHM'

  sharingRequestFormData?: {
    physiciansDiagnosis?: string
    dateSymptomsBegan?: string

    previousConditions?: boolean

    isAccident?: boolean
    accidentOccurredAt?: string

    primaryPaymentSources?: boolean
    financialAssistance?: boolean
  }

  letterOfExplanationData?: {
    what?: string
    where?: string
    additionalInfo?: string
  }
}

export interface SubmissionData2023CHMAddon extends SubmissionData2023Base {
  type: 'CHM-addon'
}

export interface SubmissionData2023HRA extends SubmissionData2023Base {
  type: 'HRA'
}

export function isSubmissionData2023(data: any): data is SubmissionData2023 {
  return data?._version === '2023-05-04'
}


export type SubmissionData2023 = SubmissionData2023CHM | SubmissionData2023CHMAddon | SubmissionData2023HRA


interface SubmissionData2024Base {
  _version: '2024-04-01'
}

export const SubmissionData2024Steps = [
  'sharingRequestForm',
  'medicalBillWorksheet',
  'medicalInformationRelease',
  // 'prayer-page-form',
  'maternityVerificationForm'
] as const

export type SubmissionData2024CHMStep = typeof SubmissionData2024Steps[number]

export interface SubmissionData2024CHM extends SubmissionData2024Base {
  type: 'CHM'
  workingOn?: SubmissionData2024CHMStep
  
  /** Whether documents have been generated already or not */
  documentsGenerated?: boolean
  
  sharingRequestFormData: {
    memberNumber?: string | null
    memberName?: string | null
    phone?: string | null
    email?: string | null
    credits?: boolean | null
    patientName?: string | null
    patientDOB?: string | null
    illness?: string | null
    illnessDate?: string | null
    preExistingSigns?: boolean | null
    preExistingSignsText?: string | null
    preExistingTreatment?: boolean | null
    preExistingTreatmentText?: string | null
    
    letterOfExplanation?: string | null
    
    signatureData?: string | null
    signatureDate?: string | null
  },
  
  medicalBillWorksheetData: SubmissionData2024_MedicalBillWorksheetData,
  
  medicalInformationReleaseData: {
    patientName?: string | null
    memberNumber?: string | null
    patientDOB?: string | null
    ssnLast4?: string | null
    address?: string | null
    phoneNumber?: string | null
    
    initialsData?: string | null,
    consent?: 'consent' | 'do-not-consent' | null
    
    signatureData?: string | null,
    printedNameOfPatient?: string | null,
    authorizedRepresentativeRelationship?: string | null,
    printedNameOfAuthorizedRepresentative?: string | null,
    date?: string | null
  },
  
  maternityVerificationFormData: {
    isMaternityIncident?: boolean | null
    patientName?: string | null
    memberNumber?: string | null
    isIVF?: boolean | null
    credits?: boolean | null
    
    dueDate?: string | null
    isExpectedDueDate?: boolean | null
    isActualDateOfBirth?: boolean | null
    
    deliveryLocation?: 'hospital' | 'birthing-center' | 'home' | null
    chosenHospital?: boolean | null
    paymentForms?: 'primary-insurance' | 'medicaid' | 'financial-assistance' | 'other' | null
    otherPaymentForms?: string | null
    paymentFormsStartDate?: string | null
    paymentFormsEndDate?: string | null
    
    consentMemberName?: string | null
    signatureData?: string | null
    consentDate?: string | null
  }
}

export interface SubmissionData2024_MedicalBillWorksheetData {
  memberNumber?: string | null
  patientName?: string | null

  isAddOn?: boolean | null
  isAddOnIncidentRefText?: string | null
  primaryInsurance?: boolean | null
  primaryInsuranceOther?: boolean | null
  primaryInsuranceOtherText?: string | null
  primaryInsuranceStartDate?: string | null
  primaryInsuranceEndDate?: string | null
  financialAssistance?: 'pending' | 'approved' | 'denied' | null
  provider?: string | null
  
  expenseRows: Array<SubmissionData2024Expense>
}

export type SubmissionData2024Expense = {
  expenseId: string
  dateOfService: string
  billingProvider: string
  originalCharges?: string | null
  discountAmount?: string | null
  discountType?: 'shownOnBill' | 'includedInCharge' | 'noneAvailable' | null
  paymentAmount?: string | null
  paymentType?: 'shownOnBill' | 'onPaymentPlan' | 'noneMade' | null
}

export const SubmissionData2024CHMAddonSteps = [
  // addons just have the one step (for now...)
  'medicalBillWorksheet',
] as const

export type SubmissionData2024CHMAddonStep = typeof SubmissionData2024CHMAddonSteps[number]

export interface SubmissionData2024CHMAddon extends SubmissionData2024Base {
  type: 'addon'
  workingOn?: SubmissionData2024CHMAddonStep
  
  /** Whether documents have been generated already or not */
  documentsGenerated?: boolean
  
  medicalBillWorksheetData: SubmissionData2024_MedicalBillWorksheetData,
}

export interface SubmissionData2024HRA extends SubmissionData2023Base {
  type: 'HRA'
}


export function isSubmissionData2024(data: any): data is SubmissionData2024 {
  return data?._version === '2024-04-01' 
}

export type SubmissionData2024 = SubmissionData2024CHM | SubmissionData2024CHMAddon | SubmissionData2024HRA

/** The "Data" column for submissions migrated in 20230421225834_submissions.sql */
export interface SubmissionDataV0 {
  /** The ID of the expenses that were submitted */
  expenses: string[],
  /** The Folder where the generated PDFs reside */
  folder: string
}
