import type { PostgrestError } from "@supabase/supabase-js"
import type { Tables } from "../../types/supabase"
import type { Add } from "./syncAdd"
import type { Delete } from "./syncDelete"
import type { Update } from "./syncUpdate"

/**
 * The list of tables which can be synced.
 * A syncable table must define the following columns:
 * - id: string
 * - membership_id: string
 * - updated_at: string
 * - deleted_at: string | null
 */
export type SyncableTable = typeof SyncableTables[number]

export const SyncableTables = [
  'expenses',
  'incidents',
  'attachments',
  'submissions',
  'advances',
  'advance_requests',
  'notifications',
] as const

export function raiseUnknownSyncableTable(table: never): never {
  throw new Error(`Unknown syncable table: ${table}`)
}

// Assert that all syncable tables have an ID, updated_at, and deleted_at.
// If this causes a Typescript error, it means a table listed above
// does not have the correct column types (as defined in src/types/supabase.ts)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertSyncableTable: {
  id: string,
  updated_at: string,
  deleted_at?: string | null,
} = {} as Tables[SyncableTable]['Row']

export type SyncAction<Table extends SyncableTable = SyncableTable> =
  Add<Table> |
  Delete<Table> |
  Update<Table>

export type RowSyncResponse<Table extends SyncableTable> = {
  result: 'applied'
  data: Tables[Table]['Row'] | null
  error?: undefined,
} | {
  result: 'rejected'
  data: Tables[Table]['Row'] | null
  error?: undefined,
} | {
  result: 'error'
  data?: undefined,
  error: Error | PostgrestError
}
