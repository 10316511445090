import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { MembershipRoleRow, MembershipRoleType, MembershipRow, OrganizationRow, ProfileRow, SettingsRow } from '../../types/supabase'
import type { DeepNullable, NotNull } from '../../types/util'
import { PURGE } from 'redux-persist';
import pick from 'lodash/pick'
import { redactName, redactObjectExceptKeys } from '../../lib/util/redact'
import { keysPresent } from '../../lib/util/present'

export type ProfileModel = {
  id: string
  updated_at?: string | null | undefined
  full_name: string
  date_of_birth: string
  address: string
  phone: string
  phone_type?: string | null | undefined
  signature_data?: string | null | undefined
  ssn_last_4?: string | null | undefined
  
  onboarding_step_completed_at?: string | null | undefined
  onboarding_steps_completed?: string[] | null | undefined
  account_deletion_requested_at?: string | null | undefined
}

/**
 * Returns true if the profile has all the required fields filled out
 */
export const isFilledProfile = keysPresent('full_name', 'date_of_birth', 'phone', 'address')

export const OnboardingFlowStates = [
  'setMembership',
  'fillProfile',
  'startTour'
] as const

export type OnboardingFlowState = (typeof OnboardingFlowStates)[number]

export function hasCompletedOnboarding({onboarding_steps_completed}: Pick<ProfileRow, 'onboarding_steps_completed'>) {
  if (!onboarding_steps_completed) { return false }

  return OnboardingFlowStates.every((step) => onboarding_steps_completed.includes(step))
}

export type MembershipSliceState = {
  uninitialized?: false

  // These are intentionally marked as non-nullable because the initializer
  // ensures that these are set, otherwise it takes you to onboarding.  So
  // the rest of the app doesn't need to worry if these are null or not
  userId: string
  fullName: string
  onboardingCompletedAt: number
  membershipId: string
  member_number: string,
  role: MembershipRoleType
  profile: ProfileModel | null
  settings: SettingsRow | null

  organization: Pick<OrganizationRow, 'id' | 'name' | 'customizations' | 'feature_flags'> | null
}

/**
 * The result of a Supabase query for the user's current membership info
 */
export type OnSyncMembershipPayload = {
  userId: string,
  membership: MembershipRow,
  role: MembershipRoleRow
  profile?: ProfileRow | null
  settings?: SettingsRow
  
  organization: Pick<OrganizationRow, 'id' | 'name' | 'customizations' | 'feature_flags'> | null
}

export interface CreateMembershipPayload {
  membership: NotNull<MembershipRow, 'member_number'>,
  role: MembershipRoleRow
}

const initialState = {
  uninitialized: true
} as unknown as MembershipSliceState

const membershipSlice = createSlice({
  name: 'membership',
  initialState: {...initialState},
  reducers: {
    /**
     * Dispatched by the initializer when the state is loaded from the Supabase server
     */
    onSyncMembership(state, action: PayloadAction<OnSyncMembershipPayload>) {
      const {userId, membership, role, profile, settings} = action.payload

      let onboardingCompletedAt: number | undefined
      if (profile) {
        if (hasCompletedOnboarding(profile) && profile.onboarding_step_completed_at) {
          onboardingCompletedAt = Date.parse(profile.onboarding_step_completed_at)
        }
      }

      // reset the state
      return {
        userId,
        // See comment above as to why these are marked non-null
        membershipId: membership?.id || state.membershipId,
        member_number: membership?.member_number || state.member_number,
        role: role?.role || state.role,
        fullName: profile?.full_name! || state.fullName,
        profile: (profile && isFilledProfile(profile)) ? profile : null,
        settings: settings || state.settings,
        onboardingCompletedAt: onboardingCompletedAt || state.onboardingCompletedAt,
        organization: action.payload.organization ? pick(action.payload.organization, 'id', 'name', 'customizations', 'feature_flags') : null
      }
    },
    onUpdateMemberNumber(state, action: PayloadAction<{ member_number: string }>) {
      state.member_number = action.payload.member_number
    },
    setProfile(state, action: PayloadAction<{ profile: ProfileModel }>) {
      const {profile} = action.payload
      state.profile = profile
      state.fullName = profile.full_name
    },
    
    setSettings(state, action: PayloadAction<{ settings: SettingsRow }>) {
      const {settings} = action.payload
      state.settings = settings
    },
    /**
     * Creates a new membership, setting this user as Admin
     */
    createMembership(state, action: PayloadAction<CreateMembershipPayload>) {
      const { membership, role } = action.payload
      state.member_number = membership.member_number
      state.membershipId = membership.id
      state.role = role.role
    }
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    })

    return builder
  }
})

// Action creators are generated for each case reducer function
export const { onSyncMembership, onUpdateMemberNumber, setProfile, setSettings, createMembership } = membershipSlice.actions

export default membershipSlice.reducer

export function redactMembership(state: MembershipSliceState): DeepNullable<MembershipSliceState> {
  return {
    ...pick(state, 'userId', 'membershipId', 'role',
      'onboardingCompletedAt'),
    fullName: redactName(state?.fullName),
    member_number: 'REDACTED',
    profile: null,
    organization: state.organization ?
      redactObjectExceptKeys(state.organization, 'id') :
      null,
    settings: state.settings ?
      redactObjectExceptKeys(state.settings,
        'id', 'user_id', 'created_at', 'updated_at', 'textract_opt_in') :
      null
  }
}
