import { Customizations, selectCustomizations } from "../reduxToolkit/selectors/customizations";
import { useAppSelector } from "./reduxToolkit";

export function useCustomizations(): Customizations {
  return useAppSelector(selectCustomizations)
}

export function useCustomization<TKey extends keyof Customizations>(key: TKey): Customizations[TKey] {
  return useAppSelector(s => selectCustomizations(s)[key])
}
