import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IncidentInsert, IncidentRow, IncidentUpdate, NotDeleted } from '../../types/supabase'
import type { DeepPartialNullable, NotNull } from '../../types/util'
import { PURGE } from 'redux-persist';
import { redactName, redactObjectExceptKeys } from '../../lib/util/redact'
import { replaceOrInsert } from '../../lib/util/replaceOrInsert'
import { onSyncDownComplete } from './actions/onSyncDownComplete';

/**
 * The required fields for an incident model.
 *
 * The incident must:
 * - not be deleted
 * - have an ID
 * - have a created_at timestamp
 * - have an updated_at timestamp
 * - have a start_date
 */
export type IncidentInsertPayload = IncidentModel

/**
 * The required fields for updating an existing incident.
 * Updating only requires us to set the values that we intend to update,
 *  but we must match the ID and provide a new updated_at timestamp.
 */
type IncidentUpdatePayload = NotNull<NotDeleted<IncidentUpdate>, 'id' | 'updated_at'>

/**
 * The local model of an Incident row, which may be a row from the server or a row that has been
 * inserted locally and not yet synced to the server.
 */
export type IncidentModel = {
  id: string
  updated_at: string

  created_at: string
  created_by_user_id: string

  membership_id: string

  start_date: string
  description: string
  patient_dob: string
  patient_name: string

  /**
   * If set to true, this incident will always produce a CHM Add-On submission.
   *
   * This tells us the user has previously submitted an incident to CHM, but did not track it through the app.
   * They then want to track the add-ons to the incident via the app.
   */
  is_addon_incident?: boolean | null

  submitted_at?: string | null
  deleted_at?: string | null
  
  is_maternity?: boolean | null
  maternity_due_date?: string | null
  
  maternity_chm_call_completed_at?: string | null
}

export type IncidentsSliceState = {
  incidents: Array<IncidentModel>

  lastLoadFromServer?: number
}

const initialState: IncidentsSliceState = {
  incidents: [],
}

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    addIncident(state, action: PayloadAction<IncidentInsertPayload>) {
      state.incidents.push(action.payload)
    },
    updateIncident(state, action: PayloadAction<IncidentUpdatePayload>) {
      const i = state.incidents.findIndex((t) => t.id === action.payload.id)
      if (i < 0) {
        throw new Error(`Could not find incident with id ${action.payload.id}`)
      }

      // Replace the record with the updated values
      state.incidents[i] = {
        ...state.incidents[i],
        ...action.payload,
      }
    },
    deleteIncident(state, action: PayloadAction<{ id: string, updated_at: string, deleted_at: string }>) {
      // Remove it out of the incidents array
      const i = state.incidents.findIndex((e) => e.id === action.payload.id)
      if (i >= 0) {
        state.incidents.splice(i, 1)
      }
    }
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    }).addCase(onSyncDownComplete, (state, action) => {

      for (const incident of action.payload.incidents?.updated || []) {
        replaceOrInsert(state.incidents, incident)
      }
      for (const deletedIncident of action.payload.incidents?.deleted || []) {
        const i = state.incidents.findIndex((t) => t.id === deletedIncident.id)
        if (i >= 0 && state.incidents[i].updated_at <= deletedIncident.updated_at) {
          state.incidents.splice(i, 1)
        }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  addIncident,
  updateIncident,
  deleteIncident
} = incidentsSlice.actions

export type IncidentsSliceAction = ReturnType<typeof incidentsSlice.actions[keyof typeof incidentsSlice.actions]>

export function isIncidentsSliceAction(action: any): action is IncidentsSliceAction {
  return action.type?.startsWith(incidentsSlice.name)
}

export default incidentsSlice.reducer

/*
 * Redact all sensitive information from the expenses and incidents, so that they can be sent to Analytics tools like
 * Sentry or Amplitude.
 * Due to HIPAA compliance, we cannot send any patient information to these tools.
 * The redaction operates with a whitelist to ensure that we don't accidentally send any sensitive information in the future.
*/

export function redactIncidents(state: IncidentsSliceState): DeepPartialNullable<IncidentsSliceState> {
  return {
    incidents: state.incidents.map(redactIncident),
  }
}

export function redactIncident(incident: Partial<IncidentModel>): DeepPartialNullable<IncidentModel> {
  return {
    ...redactObjectExceptKeys(incident,
      'id', 'created_at', 'updated_at', 'created_by_user_id',
      'membership_id', 'start_date'),
    patient_name: redactName(incident.patient_name),
  }
}

export function redactIncidentsSliceAction(action: IncidentsSliceAction) {
  switch(action.type) {
    case addIncident.type:
    case updateIncident.type:
      return {
        type: action.type,
        payload: redactIncident(action.payload)
      }

    case deleteIncident.type:
      return {
        type: action.type,
        payload: {
          id: action.payload.id,
          updated_at: action.payload.updated_at
        }
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

// Asert we can assign an IncidentRow from the DB to an ExpenseModel in Redux
const _assertIncidentRowAssignableToIncidentModel: IncidentModel = {} as IncidentRow

// Assert we can send a new IncidentModel from Redux to an IncidentInsert in the DB
const _assertIncidentModelAssignableToIncidentInsert: IncidentInsert = {} as IncidentModel

// Assert we can update an IncidentRow in the DB from a partial IncidentModel in Redux
const _assertIncidentModelAssignableToIncidentUpdate: IncidentUpdate = {} as Partial<IncidentModel>
